<script setup>
import { ref, computed, watch } from 'vue';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import { useRouter } from 'vue-router';
import Popper from 'vue3-popper';
import { FAILURE_ACTION_TYPE_ID } from '../../data/constants/appConstants';

const TABLE_USE_CASE_ID = 'failuresDashboardRegister';

const props = defineProps({
  startDate: { type: String, default: null },
  endDate: { type: String, default: null }
});

const userFilter = computed(() => {
  const result = {
    id_action_type: [FAILURE_ACTION_TYPE_ID]
  };

  if (props.startDate != null) result.creation_date_start = props.startDate + ' 00:00:00';
  if (props.endDate != null) result.creation_date_end = props.endDate + ' 23:59:59';

  return result;
});

const dictRefKey = ref(0);
const router = useRouter();

const tableColumns = [
  {
    key: 'order_number',
    header: 'Nr zlecenia',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'date',
    header: 'Data zgłoszenia',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;',
    computed: true
  },
  {
    key: 'workerName',
    header: 'Brygadzista',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;',
    computed: true,
    requiredKeysOverride: ['worker']
  },
  {
    key: 'failure_reason.name',
    header: 'Typ niepowodzenia',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  }
];

watch(
  () => [props.startDate, props.endDate],
  () => {
    dictRefKey.value++;
  }
);

function onShowOrderButtonClicked(action) {
  let routeData = router.resolve({
    name: 'orderDetails',
    params: { id: action.id_order, actionId: action.id }
  });

  window.open(routeData.href, '_blank');
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>Niepowodzenia</h5>
      <div class="ibox-tools">Dla zakresu: {{ props.startDate }} - {{ props.endDate }}</div>
    </div>

    <div class="ibox-content">
      <DictionaryTable
        :key="dictRefKey"
        :showHeader="false"
        :showBorder="false"
        url="action/raportRegister"
        :columns="tableColumns"
        :customButtons="true"
        :showTableElementsInfo="false"
        searchFromOutside=""
        :showSearchBar="false"
        :userFilter="userFilter"
        :striped="false"
        :use-case-id="TABLE_USE_CASE_ID"
        content-custom-style="padding: 0"
      >
        <template #date="{ element }">
          {{ element.date != null ? getDateWithoutTime(element.date) : '-' }}
        </template>

        <template #workerName="{ element }">
          {{
            element.worker?.firstName && element.worker?.lastName
              ? `${element.worker.firstName} ${element.worker.lastName}`
              : '-'
          }}
        </template>

        <template #mapOrPoint="{ element }">
          {{ element.dem_point != null ? 'Punkt' : 'Mapa' }}
        </template>

        <template #note="{ element }">
          <Popper
            v-if="element?.note != null"
            arrow
            :content="'Treść: ' + element.note"
            class="light"
          >
            <i class="fa fa-check" style="color: #049d04; cursor: pointer" />
          </Popper>
          <span v-else>-</span>
        </template>

        <template #buttons="{ element }">
          <div class="buttons-container">
            <button
              type="button"
              class="btn btn-outline-primary btn-xs"
              @click="onShowOrderButtonClicked(element)"
              onclick="event.stopPropagation()"
            >
              Pokaż zlecenie
            </button>
          </div>
        </template>
      </DictionaryTable>
    </div>
  </div>
</template>

<style scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
}

.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #d6dfe4;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
