import {
  ACCESS_KEY_ACTION_ADD_BUTTON,
  ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON
} from '../../data/constants/authConstants';
import {
  ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME,
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME,
  ADD_ACTION_TO_DEMPOINT_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { isDemPointSingle } from '../demPointsHelper.js';
import snippets from '../htmlSnippets/actionsTooltipSnippets.js';

export function getCreatedDemPointMarkerTooltip(
  lat,
  lon,
  demPoint,
  authStore,
  hideOperationalButtons
) {
  const addActionToDemPointButton =
    authStore.canAccess(ACCESS_KEY_ACTION_ADD_BUTTON) &&
    !hideOperationalButtons &&
    isDemPointSingle(demPoint)
      ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${ADD_ACTION_TO_DEMPOINT_BUTTON_TYPE_NAME}:${demPoint?.id}' id_demPoint='${demPoint?.id}' click_action_type='${ADD_ACTION_TO_DEMPOINT_BUTTON_TYPE_NAME}'>Dodaj akcję</button>`
      : '';

  const toggleDemPointButton =
    authStore.canAccess(ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON) && !hideOperationalButtons
      ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}:${
          demPoint?.id
        }' id_demPoint='${
          demPoint?.id
        }' click_action_type='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}' demPoint_add_action_allowed='${
          demPoint.add_action_allowed
        }'>${demPoint.add_action_allowed == 1 ? 'Dezaktywuj' : 'Aktywuj'}</button>`
      : '';

  const addEditNoteButton = !hideOperationalButtons
    ? snippets.addEditDemPointNoteButtonSnippet(demPoint)
    : '';

  let coordsCaption = `${lat}, ${lon} <button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${demPoint?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  coordsCaption += `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${demPoint?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;
  const tag = demPoint?.oznaczenie ? '<br/><b>' + demPoint?.oznaczenie + '</b>' : '';
  const copyTagButton =
    tag === ''
      ? ''
      : `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${location?.id}:tag' tag='${demPoint.oznaczenie}'click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  const tagSection = tag + copyTagButton;
  let noteCaption = demPoint.note ? `<br/>Uwagi: <i>${demPoint.note}</i>` : '';

  const buttonsSection =
    toggleDemPointButton || addActionToDemPointButton || addEditNoteButton
      ? `<br/><div class='mt-1' style='display: flex; gap: 5px'>` +
        toggleDemPointButton +
        addActionToDemPointButton +
        addEditNoteButton +
        '</div>'
      : '';

  return coordsCaption + tagSection + noteCaption + buttonsSection;
}
