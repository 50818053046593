<script setup>
import { ref, computed, watch } from 'vue';
import ChartPanel from './ChartPanel.vue';

const props = defineProps({
  data: { type: Object, default: null },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

const chartPanelKey = ref(0);
const workersIds = ref([]);
const graphDatasets = ref(null);
const yLabels = ref(null);
const maxTotal = ref(0);

watch(
  () => props.data,
  () => {
    if (props.data) {
      refreshChart();
    }
  }
);

const dataValid = computed(() => {
  if (!Array.isArray(props.data?.users_dems?.data)) return false;
  if (!Array.isArray(props.data?.users_failures?.data)) return false;

  return true;
});

const dataAvailable = computed(() => dataValid.value && workersIds.value.length > 0);

function refreshChart() {
  workersIds.value = [];
  graphDatasets.value = null;
  yLabels.value = null;
  maxTotal.value = 0;

  if (!dataValid.value) return;

  props.data.users_dems.data.map((item) => {
    workersIds.value.push(item.id_worker);
  });

  props.data.users_failures.data.map((item) => {
    if (!workersIds.value.includes(item.id_worker)) {
      workersIds.value.push(item.id_worker);
    }
  });

  graphDatasets.value = [
    {
      label: props.data.users_dems.title,
      color: '#1ab394',
      data: props.data.users_dems.data.map((item) => {
        return [item.total, workersIds.value.indexOf(item.id_worker) + 1];
      })
    },
    {
      label: props.data.users_failures.title,
      color: '#FF0000',
      data: props.data.users_failures.data.map((item) => {
        return [item.total, workersIds.value.indexOf(item.id_worker) + 1];
      })
    }
  ];

  //TODO add if horizontal bar too wide
  // if (graphDatasets.value[0].data.length < 2 && graphDatasets.value[1].data.length < 2) {
  //   graphDatasets.value[0].data.push([0, workersIds.value.length + 1]);
  // }

  yLabels.value = workersIds.value.map((id) => {
    const worker =
      props.data.users_dems.data.find((item) => item.id_worker === id) ??
      props.data.users_failures.data.find((item) => item.id_worker === id);
    return [workersIds.value.indexOf(id) + 1, `${worker.firstName} ${worker.lastName}`];
  });

  maxTotal.value = Math.max(
    Math.max(...props.data.users_dems.data.map((item) => item.total)),
    Math.max(...props.data.users_failures.data.map((item) => item.total))
  );

  chartPanelKey.value += 1;
}

const graphOptions = computed(() => {
  if (!dataValid.value) return null;

  return {
    series: {
      bars: {
        show: true,
        horizontal: true,
        barWidth: 0.75,
        align: 'center',
        fillColor: {
          colors: [{ opacity: 0.3 }, { opacity: 0.4 }]
        },
        lineWidth: 1
      }
    },
    xaxis: { autoscaleMargin: 0.05, max: maxTotal.value * 1.2 }, // 20% margin
    yaxis: { ticks: yLabels.value, autoscaleMargin: 0.05 },
    grid: {
      color: '#bababa',
      hoverable: true,
      clickable: true,
      borderWidth: 0
    },
    legend: {
      show: true,
      position: 'ne'
    },
    tooltip: true,
    tooltipOpts: {
      // eslint-disable-next-line no-unused-vars
      content: (label, x, y) => {
        switch (label) {
          case 'Pracownicy - demontaże':
            return `Demontaże: ${x}`;
          case 'Pracownicy - niepowodzenia':
            return `Niepowodzenia: ${x}`;
          default:
            return `${label}: ${x}`;
        }
      }
    }
  };
});
</script>

<template>
  <ChartPanel
    :key="chartPanelKey"
    header="Akcje brygadzistów"
    :stats="null"
    :graphDatasets="graphDatasets"
    :graphOptions="graphOptions"
    :rightHeaderText="props.rightHeaderText"
    :isLoading="props.isLoading"
    :manuallyShowNoData="!dataAvailable"
  />
</template>

<style scoped></style>
