<script setup>
/* eslint-disable no-undef */
import { ref, computed, watch, onMounted } from 'vue';
import {
  MODAL_REGIONS_TABLE,
  MODAL_BOROUGHS_TABLE,
  MODAL_STORAGE_TYPES_TABLE
} from '../../data/constants/modalConstants';
import ModalInputField from '../utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  oneFilterPerRow: { type: Boolean, default: false },
  hideSearchBar: { type: Boolean, default: false },
  filterPanel: { type: Object, default: null },
  filterPanelDisplay: { type: Object, default: null },
  appliedFilter: { type: Object, default: null },
  searchBox: { type: String, default: '' },
  customSearchBarCssClass: { type: String, default: null },
  showFilterPanelOnMounted: { type: Boolean, default: false }
});
const emit = defineEmits([
  'updateFilterPanel',
  'updateFilterPanelDisplay',
  'fetchData',
  'searchChanged'
]);

const searchString = ref(null);
const modalTable = ref();
const collapseLink = ref(null);

const filtersCount = computed(() => {
  let count = 0;

  for (const property in props.appliedFilter) {
    if (props.appliedFilter[property] != null) count++;
  }

  return count;
});

const regionsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.regions, props.appliedFilter?.regions);
});

const boroughsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.boroughs, props.appliedFilter?.boroughs);
});

const storageTypesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.storageTypes, props.appliedFilter?.storageTypes);
});

watch(
  () => props.searchBox,
  () => {
    searchString.value = props.searchBox;
  },
  { immediate: true }
);

onMounted(() => {
  if (collapseLink.value) {
    $(collapseLink.value).off('click').on('click', handleCollapseClick);
  }

  if (props.showFilterPanelOnMounted) {
    setTimeout(() => {
      toggleFiltersPanel();
    }, 500);
  }
});

function handleCollapseClick(e) {
  e.preventDefault();

  var ibox = $(this).closest('div.ibox');
  var button = $(this).find('i');
  var content = ibox.children('.ibox-content');

  content.slideToggle(200);
  button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');

  setTimeout(function () {
    ibox.resize();
    ibox.find('[id^=map-]').resize();
  }, 50);
}

function toggleFiltersPanel() {
  if (collapseLink.value) {
    handleCollapseClick.call(collapseLink.value, new Event('click'));
  }
}

function onModalCompleted(data, data2) {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.regionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.boroughsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_STORAGE_TYPES_TABLE:
      updatedFilterPanel.storageTypes = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.storageTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onModalRejected() {
  modalTable.value.hide();

  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = null;
      updatedFilterPanelDisplay.regionsDisplayName = null;
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = null;
      updatedFilterPanelDisplay.boroughsDisplayName = null;
      break;
    case MODAL_STORAGE_TYPES_TABLE:
      updatedFilterPanel.storageTypes = null;
      updatedFilterPanelDisplay.storageTypesDisplayName = null;
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onClearAllFiltersButtonClicked() {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  for (const property in updatedFilterPanel) {
    updatedFilterPanel[property] = null;
  }

  for (const property in updatedFilterPanelDisplay) {
    updatedFilterPanelDisplay[property] = null;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (isNotEmptyArray(props.filterPanel?.regions))
    userFilter.id_region = props.filterPanel.regions.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.boroughs))
    userFilter.id_borough = props.filterPanel.boroughs.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.storageTypes))
    userFilter.dem_elements = props.filterPanel.storageTypes.map((item) => item.id);

  return userFilter;
}

function multifilterApplied(array1, array2) {
  if (array1 == null && array2 == null) return true;
  if ((array1 == null && array2 != null) || (array1 != null && array2 == null)) return false;

  const array1Ids = array1.map((x) => x.id);
  const array2Ids = array2.map((x) => x.id);

  if (array1Ids.length !== array2Ids.length) return false;

  return array1Ids.every((value) => array2Ids.includes(value));
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    filter: getUserFilter()
  });
}

function searchChanged() {
  emit('searchChanged', searchString.value);
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="userFilterEnabled" class="col-sm-6">
              <a
                type="button"
                ref="collapseLink"
                class="collapse-link-modified btn btn-w-m btn-default"
              >
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry &nbsp;
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!hideSearchBar"
              :class="
                customSearchBarCssClass != null
                  ? customSearchBarCssClass
                  : longerSearchBar
                  ? 'col-sm-4 ' + (userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchString"
                  v-on:keyup.enter="search"
                  v-on:keyup="searchChanged"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Region"
                  :titleCssClass="regionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.regionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_REGIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.regions
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Gmina"
                  :titleCssClass="boroughsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.boroughsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_BOROUGHS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.boroughs
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Typ elementu"
                  :titleCssClass="storageTypesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.storageTypesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_STORAGE_TYPES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.storageTypes
                      }
                    })
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      :showCloseButton="true"
      overrideCloseButtonText="Zatwierdź wybór"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
