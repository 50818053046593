<script setup>
import { computed } from 'vue';
import PieChart from './PieChart.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  data: { type: Object, default: null },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

const pieChartData = computed(() => {
  if (!isNotEmptyArray(props.data?.action_types?.data)) return null;

  const chartData = [];
  const sum = props.data.action_types.data.reduce((acc, item) => acc + item.total, 0);

  props.data?.action_types?.data.forEach((item) => {
    chartData.push({
      label: item.name + ` (${((item.total / sum) * 100).toFixed(1)}%)`,
      data: item.total
    });
  });

  chartData.sort((a, b) => {
    return a.data === b.data ? 0 : a.data < b.data ? 1 : -1;
  });

  return chartData;
});

const graphOptions = {
  series: {
    pie: { show: true, innerRadius: 0.5 }
  },
  grid: { hoverable: true },
  tooltip: true,
  tooltipOpts: {
    content: '%s',
    shifts: { x: 20, y: 0 },
    defaultTheme: false
  },
  legend: { show: true, container: '#oiuoiuouio' }
};
</script>

<template>
  <PieChart
    header="Powody niepowodzeń"
    :graphDataset="pieChartData"
    :graphOptions="graphOptions"
    :rightHeaderText="props.rightHeaderText"
    legentDivId="oiuoiuouio"
    :isLoading="props.isLoading"
  />
</template>

<style scoped></style>
