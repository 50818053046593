<script setup>
import { ref, computed, watch, onMounted } from 'vue';
/* eslint-disable no-undef */
import {
  MODAL_USERS_TABLE,
  MODAL_ORDERS_TABLE,
  MODAL_ACTION_TYPES_TABLE,
  MODAL_DISMANTLING_FAILURE_REASONS_TABLE,
  MODAL_DEM_ELEM_TYPES_TABLE,
  MODAL_BOROUGHS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_RANGES_TABLE,
  MODAL_ADDITIONAL_DISMANTLING_SOURCES_TABLE
} from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { useAuthStore } from '../../stores/auth';
import { ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER } from '../../data/constants/authConstants';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import DateRangeInput from '../utils/DateRangeInput.vue';
import { FAILURE_ACTION_TYPE_ID } from '../../data/constants/appConstants';

const COORDINATORS_MODAL_URL = 'user/coordinators';
const WORKERS_MODAL_URL = 'user/workers';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  hideSearchBar: { type: Boolean, default: false },
  oneFilterPerRow: { type: Boolean, default: false },
  filterPanel: { type: Object, default: null },
  filterPanelDisplay: { type: Object, default: null },
  appliedFilter: { type: Object, default: null },
  searchBox: { type: String, default: '' },
  customSearchBarCssClass: { type: String, default: null }
});

const emit = defineEmits([
  'updateFilterPanel',
  'updateFilterPanelDisplay',
  'fetchData',
  'searchChanged'
]);

const searchString = ref(null);
const modalTable = ref();
const actionKind = ref(props.filterPanel.actionKind);
const lastActionOnly = ref(props.filterPanel.lastActionOnly);
const priorityChange = ref(props.filterPanel.priorityChange);
const authStore = useAuthStore();

const filtersCount = computed(() => {
  let count = 0;

  for (const property in props.appliedFilter) {
    if (isFilterDateRange(props.appliedFilter[property])) {
      if (
        props.appliedFilter[property].startDate != null ||
        props.appliedFilter[property].endDate != null
      )
        count++;
    } else {
      if (props.appliedFilter[property] != null) count++;
    }
  }

  return count;
});

function isFilterDateRange(filter) {
  return filter?.startDate !== undefined && filter?.endDate !== undefined;
}

const creationDateApplied = computed(() => {
  return (
    filterApplied(
      props.filterPanel?.creationDate?.startDate,
      props.appliedFilter?.creationDate?.startDate
    ) &&
    filterApplied(
      props.filterPanel?.creationDate?.endDate,
      props.appliedFilter?.creationDate?.endDate
    )
  );
});

const ordersApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orders, props.appliedFilter?.orders);
});

const actionKindApplied = computed(() => {
  return filterApplied(props.filterPanel?.actionKind, props.appliedFilter?.actionKind);
});

const actionTypesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.actionTypes, props.appliedFilter?.actionTypes);
});

const failureReasonsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.failureReasons, props.appliedFilter?.failureReasons);
});

const lastActionOnlyApplied = computed(() => {
  return filterApplied(props.filterPanel?.lastActionOnly, props.appliedFilter?.lastActionOnly);
});

const workersApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.workers, props.appliedFilter?.workers);
});

const demElemTypesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.demElemTypes, props.appliedFilter?.demElemTypes);
});

const boroughsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.boroughs, props.appliedFilter?.boroughs);
});

const regionsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.regions, props.appliedFilter?.regions);
});

const countiesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.counties, props.appliedFilter?.counties);
});

const priorityChangeApplied = computed(() => {
  return filterApplied(props.filterPanel?.priorityChange, props.appliedFilter?.priorityChange);
});

const orderStatusesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orderStatuses, props.appliedFilter?.orderStatuses);
});

const coordinatorsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.coordinators, props.appliedFilter?.coordinators);
});

const orderRangesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orderRanges, props.appliedFilter?.orderRanges);
});

const additionalDismantlingSourcesApplied = computed(() => {
  return multifilterApplied(
    props.filterPanel?.additionalDismantlingSources,
    props.appliedFilter?.additionalDismantlingSources
  );
});

const showFailureFilter = computed(() => {
  return (
    !isNotEmptyArray(props.filterPanel.actionTypes) ||
    props.filterPanel.actionTypes.find((actionType) => actionType.id === FAILURE_ACTION_TYPE_ID) !=
      null
  );
});

watch(
  () => props.searchBox,
  () => {
    searchString.value = props.searchBox;
  },
  { immediate: true }
);

watch(
  () => props.filterPanel.actionKind,
  () => {
    actionKind.value = props.filterPanel.actionKind;
  }
);

watch(actionKind, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.actionKind = actionKind.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

watch(
  () => props.filterPanel.lastActionOnly,
  () => {
    lastActionOnly.value = props.filterPanel.lastActionOnly;
  }
);

watch(lastActionOnly, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.lastActionOnly = lastActionOnly.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

watch(
  () => props.filterPanel.priorityChange,
  () => {
    priorityChange.value = props.filterPanel.priorityChange;
  }
);

watch(priorityChange, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.priorityChange = priorityChange.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

onMounted(() => {
  configureCollapsingFilterPanel();
});

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function onModalCompleted(data, data2, modalProperties) {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_ORDERS_TABLE:
      updatedFilterPanel.orders = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.ordersDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.order_number !== null)
            .map((obj) => obj.order_number)
            .join(', ')
        : '-';
      break;
    case MODAL_ACTION_TYPES_TABLE:
      updatedFilterPanel.actionTypes = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.actionTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_DISMANTLING_FAILURE_REASONS_TABLE:
      updatedFilterPanel.failureReasons = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.failureReasonsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_USERS_TABLE:
      if (modalProperties?.filter?.url === COORDINATORS_MODAL_URL) {
        updatedFilterPanel.coordinators = isNotEmptyArray(data2) ? data2 : null;
        updatedFilterPanelDisplay.coordinatorsDisplayName = isNotEmptyArray(data2)
          ? data2
              .filter((obj) => obj.firstName !== null && obj.lastName !== null)
              .map((obj) => obj.firstName + ' ' + obj.lastName)
              .join(', ')
          : '-';
      } else if (modalProperties?.filter?.url === WORKERS_MODAL_URL) {
        updatedFilterPanel.workers = isNotEmptyArray(data2) ? data2 : null;
        updatedFilterPanelDisplay.workersDisplayName = isNotEmptyArray(data2)
          ? data2
              .filter((obj) => obj.firstName !== null && obj.lastName !== null)
              .map((obj) => obj.firstName + ' ' + obj.lastName)
              .join(', ')
          : '-';
      }

      break;
    case MODAL_DEM_ELEM_TYPES_TABLE:
      updatedFilterPanel.demElemTypes = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.demElemTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.boroughsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.regionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_COUNTIES_TABLE:
      updatedFilterPanel.counties = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.countiesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      updatedFilterPanel.orderStatuses = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderStatusesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_RANGES_TABLE:
      updatedFilterPanel.orderRanges = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderRangesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ADDITIONAL_DISMANTLING_SOURCES_TABLE:
      updatedFilterPanel.additionalDismantlingSources = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.additionalDismantlingSourcesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onModalRejected(modalProperties) {
  modalTable.value.hide();

  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_ORDERS_TABLE:
      updatedFilterPanel.orders = null;
      updatedFilterPanelDisplay.ordersDisplayName = null;
      break;
    case MODAL_ACTION_TYPES_TABLE:
      updatedFilterPanel.actionTypes = null;
      updatedFilterPanelDisplay.actionTypesDisplayName = null;
      break;
    case MODAL_DISMANTLING_FAILURE_REASONS_TABLE:
      updatedFilterPanel.failureReasons = null;
      updatedFilterPanelDisplay.failureReasonsDisplayName = null;
      break;
    case MODAL_USERS_TABLE:
      if (modalProperties?.filter?.url === COORDINATORS_MODAL_URL) {
        updatedFilterPanel.coordinators = null;
        updatedFilterPanelDisplay.coordinatorsDisplayName = null;
      } else if (modalProperties?.filter?.url === WORKERS_MODAL_URL) {
        updatedFilterPanel.workers = null;
        updatedFilterPanelDisplay.workersDisplayName = null;
      }

      break;
    case MODAL_DEM_ELEM_TYPES_TABLE:
      updatedFilterPanel.demElemTypes = null;
      updatedFilterPanelDisplay.demElemTypesDisplayName = null;
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = null;
      updatedFilterPanelDisplay.boroughsDisplayName = null;
      break;
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = null;
      updatedFilterPanelDisplay.regionsDisplayName = null;
      break;
    case MODAL_COUNTIES_TABLE:
      updatedFilterPanel.counties = null;
      updatedFilterPanelDisplay.countiesDisplayName = null;
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      updatedFilterPanel.orderStatuses = null;
      updatedFilterPanelDisplay.orderStatusesDisplayName = null;
      break;
    case MODAL_ORDER_RANGES_TABLE:
      updatedFilterPanel.orderRanges = null;
      updatedFilterPanelDisplay.orderRangesDisplayName = null;
      break;
    case MODAL_ADDITIONAL_DISMANTLING_SOURCES_TABLE:
      updatedFilterPanel.additionalDismantlingSources = null;
      updatedFilterPanelDisplay.additionalDismantlingSourcesDisplayName = null;
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onClearAllFiltersButtonClicked() {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  for (const property in updatedFilterPanel) {
    if (isFilterDateRange(updatedFilterPanel[property])) {
      updatedFilterPanel[property].startDate = null;
      updatedFilterPanel[property].endDate = null;
    } else {
      updatedFilterPanel[property] = null;
    }
  }

  for (const property in updatedFilterPanelDisplay) {
    updatedFilterPanelDisplay[property] = null;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (props.filterPanel?.creationDate?.startDate != null)
    userFilter.creation_date_start = props.filterPanel.creationDate.startDate + ' 00:00:00';

  if (props.filterPanel?.creationDate?.endDate != null)
    userFilter.creation_date_end = props.filterPanel.creationDate.endDate + ' 23:59:59';

  if (isNotEmptyArray(props.filterPanel?.orders))
    userFilter.id_order = props.filterPanel.orders.map((item) => item.id);

  if (props.filterPanel?.actionKind != null) userFilter.action_kind = props.filterPanel.actionKind;

  if (isNotEmptyArray(props.filterPanel?.actionTypes))
    userFilter.id_action_type = props.filterPanel.actionTypes.map((item) => item.id);

  if (showFailureFilter.value && isNotEmptyArray(props.filterPanel?.failureReasons))
    userFilter.id_failure_action_reason = props.filterPanel.failureReasons.map((item) => item.id);

  if (props.filterPanel?.lastActionOnly != null)
    userFilter.last_action_only = props.filterPanel.lastActionOnly ? 1 : 0;

  if (isNotEmptyArray(props.filterPanel?.workers))
    userFilter.id_worker = props.filterPanel.workers.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.demElemTypes))
    userFilter.id_dem_elem_type = props.filterPanel.demElemTypes.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.boroughs))
    userFilter.id_gmina = props.filterPanel.boroughs.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.regions))
    userFilter.id_region = props.filterPanel.regions.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.counties))
    userFilter.id_powiat = props.filterPanel.counties.map((item) => item.id);

  if (props.filterPanel?.priorityChange != null)
    userFilter.priority = props.filterPanel.priorityChange ? 1 : 0;

  if (isNotEmptyArray(props.filterPanel?.orderStatuses))
    userFilter.id_order_status = props.filterPanel.orderStatuses.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.coordinators))
    userFilter.id_coordinator_opl = props.filterPanel.coordinators.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.orderRanges))
    userFilter.id_order_range = props.filterPanel.orderRanges.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.additionalDismantlingSources))
    userFilter.id_extra_dem_source = props.filterPanel.additionalDismantlingSources.map(
      (item) => item.id
    );

  return userFilter;
}

function updateAppliedFilters() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function multifilterApplied(array1, array2) {
  if (array1 == null && array2 == null) return true;
  if ((array1 == null && array2 != null) || (array1 != null && array2 == null)) return false;

  const array1Ids = array1.map((x) => x.id);
  const array2Ids = array2.map((x) => x.id);

  if (array1Ids.length !== array2Ids.length) return false;

  return array1Ids.every((value) => array2Ids.includes(value));
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchString.value,
    filter: getUserFilter()
  });
}

function searchChanged() {
  emit('searchChanged', searchString.value);
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry &nbsp;
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!hideSearchBar"
              :class="
                customSearchBarCssClass != null
                  ? customSearchBarCssClass
                  : longerSearchBar
                  ? 'col-sm-4 ' + (userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchString"
                  v-on:keyup.enter="search"
                  v-on:keyup="searchChanged"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <label class="col-form-label pl-3">Filtry dla akcji</label>
            </div>

            <div class="row">
              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  inputId="creationDateRangeId"
                  label="Data zgłoszenia"
                  :labelCssClass="creationDateApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.creationDate.startDate"
                  :endDate="filterPanel.creationDate.endDate"
                  @startDateChanged="
                    (p1) => {
                      let updatedFilterPanel = props.filterPanel;
                      let creationDate = new Object();
                      creationDate.startDate = p1;
                      creationDate.endDate = updatedFilterPanel.creationDate.endDate;
                      updatedFilterPanel.creationDate = creationDate;
                      emit('updateFilterPanel', updatedFilterPanel);
                    }
                  "
                  @endDateChanged="
                    (p1) => {
                      let updatedFilterPanel = props.filterPanel;
                      let creationDate = new Object();
                      creationDate.startDate = updatedFilterPanel.creationDate.startDate;
                      creationDate.endDate = p1;
                      updatedFilterPanel.creationDate = creationDate;
                      emit('updateFilterPanel', updatedFilterPanel);
                    }
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Zlecenie"
                  :titleCssClass="ordersApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.ordersDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDERS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orders
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="actionKindApplied ? '' : 'text-warning'"
                  >
                    Rodzaj akcji</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.actionKind == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="actionKind" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.actionKind === 1 ? 'active' : ''"
                    >
                      <input type="radio" :value="1" v-model="actionKind" />
                      Punkt
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.actionKind === 2 ? 'active' : ''"
                    >
                      <input type="radio" :value="2" v-model="actionKind" />
                      Mapa
                    </label>
                  </div>
                </div>
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Typ akcji"
                  :titleCssClass="actionTypesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.actionTypesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ACTION_TYPES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.actionTypes
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  :title="`Typ niepowodzenia${showFailureFilter ? '' : ' (nieaktywny)'}`"
                  :titleCssClass="failureReasonsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.failureReasonsDisplayName"
                  :disabled="!showFailureFilter"
                  @showModal="
                    modalTable.showModal(MODAL_DISMANTLING_FAILURE_REASONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.failureReasons
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="lastActionOnlyApplied ? '' : 'text-warning'"
                    >Pokaż tylko ostatnią akcję</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lastActionOnly == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="lastActionOnly" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lastActionOnly === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="lastActionOnly" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lastActionOnly === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="lastActionOnly" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Brygadzista"
                  :titleCssClass="workersApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.workersDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: WORKERS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.workers
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Typ punktu"
                  :titleCssClass="demElemTypesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.demElemTypesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_DEM_ELEM_TYPES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.demElemTypes
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Źródło demontażu dodatkowego"
                  :titleCssClass="additionalDismantlingSourcesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.additionalDismantlingSourcesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ADDITIONAL_DISMANTLING_SOURCES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.additionalDismantlingSources
                      }
                    })
                  "
                />
              </div>
            </div>

            <div class="row mt-2 pt-2" style="border-top: 1px dashed #bbb">
              <label class="col-form-label pl-3">Filtry dla zlecenia</label>
            </div>

            <div class="row">
              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Gmina"
                  :titleCssClass="boroughsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.boroughsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_BOROUGHS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.boroughs
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Region"
                  :titleCssClass="regionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.regionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_REGIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.regions
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Powiat"
                  :titleCssClass="countiesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.countiesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_COUNTIES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.counties
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="priorityChangeApplied ? '' : 'text-warning'"
                    >Priorytet</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="priorityChange" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="priorityChange" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="priorityChange" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div
                v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER)"
                :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'"
              >
                <ModalInputField
                  title="Status zlecenia"
                  :titleCssClass="orderStatusesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderStatusesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_STATUSES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderStatuses
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Koordynator"
                  :titleCssClass="coordinatorsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.coordinatorsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: COORDINATORS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.coordinators
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Zakres prac"
                  :titleCssClass="orderRangesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderRangesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_RANGES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderRanges
                      }
                    })
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      :showCloseButton="true"
      overrideCloseButtonText="Zatwierdź wybór"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
