/**
 * Groups an array of objects by their 'featureGroupKey' property.
 *
 * @param {Array<Object>} array - The array of objects to be grouped
 * @param {string} array[].featureGroupKey - The key used for grouping the objects
 * @returns {Array<Array<Object>>} An array of arrays where each inner array contains objects with the same featureGroupKey
 *
 * @example
 * const data = [
 *   { featureGroupKey: "a", value: 1 },
 *   { featureGroupKey: "b", value: 2 },
 *   { featureGroupKey: "a", value: 3 }
 * ];
 * groupByFeatureGroupKey(data);
 * // Returns: [[{ featureGroupKey: "a", value: 1 }, { featureGroupKey: "a", value: 3 }],
 * //           [{ featureGroupKey: "b", value: 2 }]]
 */
export function groupByFeatureGroupKey(array) {
  const grouped = array.reduce((acc, obj) => {
    const key = obj.featureGroupKey;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);

    return acc;
  }, {});

  return Object.values(grouped);
}
