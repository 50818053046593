<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  transportOrderDriversSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import {
  DRIVER_IS_WAITING_ORDER_STATUS_ID,
  DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID
} from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import ManualTransportOrderPerform from '../transport/ManualTransportOrderPerform.vue';
import TransportOrderVerification from './TransportOrderVerification.vue';
import { useTableColumns } from '../../composables/tableColumns.js';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

const props = defineProps({
  filter: { type: Object, default: null },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  noItemsText: { type: String, default: 'Brak elementów do wyświetlenia' },
  showDefaultButtons: { type: Boolean, default: true },
  orderId: { type: Number, default: null },
  orderNumber: { type: String, default: null }
});

const initialColumns = [
  { key: 'status', header: 'Status', tdStyle: 'white-space: nowrap;' },
  {
    key: 'worker.firstName',
    header: 'Imię',
    sortable: true,
    sortKey: sortingOptions.FIRST_NAME,
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'worker.lastName',
    header: 'Nazwisko',
    sortable: true,
    sortKey: sortingOptions.LAST_NAME,
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  { key: 'done_detailed', header: 'Wykonano', thStyle: 'white-space: nowrap;' },
  { key: 'left_detailed', header: 'Pozostawiono', thStyle: 'white-space: nowrap;' },
  {
    key: 'note',
    header: 'Notatka',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'max-width: 200px;'
  }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns('transport_order_drivers_table', initialColumns);

const emit = defineEmits(['refresh']);
const isLoading = ref(false);
const userOrdersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();
const manualPerformUserOrderTransport = ref(null);
const manualTransportOrderPerform = ref();
const verifyTransportOrderComponent = ref();
const verifyTransportUserOrder = ref(null);

// created
fetchData();

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(
    page,
    paginationStore.getPaginationItemsCount(TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter.url
  )
    .then((data) => {
      userOrdersObject.value = data;
      updateTableData(userOrdersObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function search() {
  fetchData();
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function onPerformTheOrderButtonClicked(userOrderTransport) {
  manualPerformUserOrderTransport.value = userOrderTransport;
  manualTransportOrderPerform.value?.show();
}

function onItemsPerPageChanged() {
  fetchData();
}

function canPerformTheOrder(userOrder) {
  return userOrder.status?.id === DRIVER_IS_WAITING_ORDER_STATUS_ID;
}

function isOrderForVerification(userOrder) {
  return userOrder.status?.id === DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID;
}

function onVerifyTheOrderButtonClicked(userOrderTransport) {
  verifyTransportUserOrder.value = userOrderTransport;
  verifyTransportOrderComponent.value?.show();
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div class="row">
        <div :class="props.longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo :metaObject="userOrdersObject?.meta ?? null" />

      <div v-if="isNotEmptyArray(userOrdersObject?.data)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="userOrder in userOrdersObject.data" :key="userOrder.id">
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Status column -->
                  <template v-if="column.key === 'status'">
                    <span
                      class="label label-primary"
                      :style="
                        userOrder?.status?.color != null
                          ? 'background-color:' + userOrder.status.color
                          : ''
                      "
                    >
                      {{ userOrder?.status?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- Done detailed column -->
                  <template v-else-if="column.key === 'done_detailed'">
                    <div v-if="userOrder?.done_detailed">
                      <div v-for="(propValue, propName) in userOrder.done_detailed" :key="propName">
                        {{ propName }}: {{ propValue }}
                      </div>
                    </div>
                    <div v-else>-</div>
                  </template>

                  <!-- Left detailed column -->
                  <template v-else-if="column.key === 'left_detailed'">
                    <div v-if="userOrder?.left_detailed">
                      <div v-for="(propValue, propName) in userOrder.left_detailed" :key="propName">
                        {{ propName }}: {{ propValue }}
                      </div>
                    </div>
                    <div v-else>-</div>
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(userOrder, column.key) ?? '-'
                        : userOrder[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td>
                <div class="float-right">
                  <span v-if="props.showDefaultButtons">
                    <button
                      v-if="canPerformTheOrder(userOrder)"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="onPerformTheOrderButtonClicked(userOrder)"
                      onclick="event.stopPropagation()"
                    >
                      Zamknij transport kierowcy
                    </button>
                    <button
                      v-if="isOrderForVerification(userOrder)"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="onVerifyTheOrderButtonClicked(userOrder)"
                      onclick="event.stopPropagation()"
                    >
                      Weryfikuj zlecenie
                    </button>
                  </span>
                  <slot name="buttons" :userOrder="userOrder"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="userOrdersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ props.noItemsText }}</p>
      </div>

      <div v-if="isNotEmptyArray(userOrdersObject?.data)" class="row mt-3">
        <div class="col-xl-3">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="userOrdersObject?.data"
            align="center"
            :data="userOrdersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>

  <ManualTransportOrderPerform
    ref="manualTransportOrderPerform"
    v-if="manualPerformUserOrderTransport"
    :userOrderTransport="manualPerformUserOrderTransport"
    :orderId="props.orderId"
    :orderNumber="props.orderNumber"
    @succeeded="emit('refresh')"
  />

  <TransportOrderVerification
    ref="verifyTransportOrderComponent"
    v-if="verifyTransportUserOrder"
    :userOrderTransport="verifyTransportUserOrder"
    :orderId="props.orderId"
    :orderNumber="props.orderNumber"
    @succeeded="emit('refresh')"
  />
</template>

<style scoped></style>
