<script setup>
/* eslint-env jquery */
import { ref, computed, watch, onMounted } from 'vue';
import StatsList from './StatsList.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  header: { type: String, default: null },
  stats: { type: Array, default: () => [] },
  graphDatasets: { type: Array, default: () => [] },
  graphOptions: { type: Object, default: null },
  afterInitializeAction: { type: Function, default: null },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false },
  manuallyShowNoData: { type: Boolean, default: false }
});

const chartRef = ref(null);

const statsPanelEnabled = computed(() => isNotEmptyArray(props.stats));

watch([() => props.graphDatasets, () => props.graphOptions], () => {
  initializeGraph();
});

function initializeGraph() {
  if (!isNotEmptyArray(props.graphDatasets)) return;
  if (props.graphOptions == null) return;

  const plot = $.plot($(chartRef.value), props.graphDatasets, props.graphOptions);

  if (props.afterInitializeAction) {
    props.afterInitializeAction(plot);
  }
}

onMounted(() => {
  initializeGraph();
});
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>{{ props.header }}</h5>
      <div class="ibox-tools">
        {{ props.rightHeaderText }}
      </div>
    </div>
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div v-show="!props.manuallyShowNoData" class="chart-container">
        <div class="flot-chart" style="flex-grow: 5">
          <div ref="chartRef" class="flot-chart-content"></div>
        </div>
        <StatsList v-if="statsPanelEnabled" :stats="props.stats" style="flex-grow: 1" />
      </div>

      <div v-show="props.manuallyShowNoData">
        <p class="text-center">Brak danych do wyświetlenia</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}
</style>
