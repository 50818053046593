import repository from './repository.js';

export default {
  async getStaticData() {
    return repository
      .get('/order/dashboardStatic')
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getGraphData(year) {
    return repository
      .post('/order/dashboardGraph', { year: year.toString() })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getDateRangeData(startDate, endDate) {
    return repository
      .post('/order/dashboardDate', { date_start: startDate, date_end: endDate })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  }
};
