<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants';
import {
  transportOrdersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { isNonEmptyString, isNotEmptyArray, trimString } from '../../helpers/utilsHelper';
import { TRANSPORT_ORDERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { useTableColumns } from '../../composables/tableColumns';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');

const props = defineProps({
  filter: { type: Object, default: null },
  selectableItems: { type: Boolean, default: false },
  showHeader: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  showTitle: { type: Boolean, default: true },
  initialUserFilter: { type: Object, default: null },
  filterFromParent: { type: Object, default: null },
  searchFromParent: { type: String, default: '' },
  useCaseId: { type: String, default: null }
});

const initialColumns = [
  {
    key: 'order_number',
    header: 'Nr zlecenia',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'order_range',
    header: 'Zakres prac',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'target',
    header: 'Elementy do likwidacji',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'done',
    header: 'Elementy zebrane',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'gmina',
    header: 'Gmina',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'region',
    header: 'Region',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'localisation',
    header: 'Lokalizacja',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'storage_date',
    header: 'Termin składowania',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'deadline',
    header: 'Termin realizacji',
    sortable: true,
    sortKey: sortingOptions.DEADLINE,
    thStyle: 'white-space: nowrap;'
  },
  {
    key: 'order_status',
    header: 'Status zlecenia',
    thStyle: 'white-space: nowrap; text-align: center;',
    tdStyle: 'white-space: nowrap; text-align: center;'
  },
  {
    key: 'delay',
    header: 'Opóźnienie (dni)',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns(
  'transport_orders_table' + (isNonEmptyString(props.useCaseId) ? `_${props.useCaseId}` : ''),
  initialColumns
);

const emit = defineEmits(['afterSuccessfulFetch', 'on-completed']);

const router = useRouter();
const isLoading = ref(false);
const ordersObject = ref({});
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();
const ordersTableFilterAndSearchChildComponent = ref(null);

// created
fetchData();

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryTransportOrders.getTransportOrdersObject(
    page,
    paginationStore.getPaginationItemsCount(TRANSPORT_ORDERS_TABLE_PAGINATION_KEY),
    props.searchFromParent,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter,
    props.filterFromParent
  )
    .then((data) => {
      ordersObject.value = data;
      emit('afterSuccessfulFetch');
      ordersTableFilterAndSearchChildComponent.value?.updateAppliedFilters();
      updateTableData(ordersObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
      //eslint-disable-next-line no-undef
      $('[data-toggle="tooltip"]').tooltip({ placement: 'right' });
    });
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function showDetails(order) {
  let routeData = router.resolve({ name: 'transportOrderDetails', params: { id: order.id } });
  window.open(routeData.href, '_blank');
}

function pickItem(order) {
  emit('on-completed', order);
}

function onItemsPerPageChanged() {
  fetchData();
}

defineExpose({ fetchData });
</script>

<template>
  <div class="ibox">
    <div
      v-if="props.showHeader"
      class="ibox-title"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <h5 v-if="props.showTitle">Zlecenia transportowe</h5>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <TableElementsInfo :metaObject="ordersObject ? ordersObject.meta : null" />

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="table-responsive">
        <table class="table table-striped first-column-fixed-table">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="selectableItems ? pickItem(order) : showDetails(order)"
              style="cursor: pointer"
            >
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Localisation column -->
                  <template v-if="column.key === 'localisation'">
                    {{
                      isNonEmptyString(order.localisation) ? trimString(order.localisation) : '-'
                    }}
                  </template>

                  <!-- Storage date column -->
                  <template v-else-if="column.key === 'storage_date'">
                    {{ order?.storage_date != null ? getDateWithoutTime(order.storage_date) : '-' }}
                  </template>

                  <!-- Deadline column -->
                  <template v-else-if="column.key === 'deadline'">
                    {{ order?.deadline != null ? getDateWithoutTime(order.deadline) : '-' }}
                  </template>

                  <!-- Order status column -->
                  <template v-else-if="column.key === 'order_status'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.order_status?.color != null
                          ? 'background-color:' + order.order_status.color
                          : ''
                      "
                    >
                      {{ order?.order_status?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(order, column.key) ?? '-'
                        : order[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="props.selectableItems ? pickItem(order) : showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="props.selectableItems">Wybierz</span>
                    <span v-else>Pokaż</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="row mt-3">
        <div class="col-xl-3">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="ordersObject?.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="TRANSPORT_ORDERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          ></PaginationCountPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.first-column-fixed-table th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}

.first-column-fixed-table > tbody > tr:nth-child(odd) > td {
  background-color: rgb(242, 242, 242);
}

.first-column-fixed-table > tbody > tr:nth-child(even) > td,
.first-column-fixed-table > thead > tr:first-child > th {
  background-color: white;
}
</style>
