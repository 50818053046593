<script setup>
/* eslint-env jquery */
import { ref, computed, onMounted } from 'vue';
import {
  MODAL_USERS_TABLE,
  MODAL_ACTIONS_REPORT_STATUSES_TABLE
} from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  showSearchBar: { type: Boolean, default: true }
});

const emit = defineEmits(['fetchData']);

const modalTable = ref();
const searchBox = ref('');
const filterPanel = ref({ user: null, actionReportStatus: null });
const filterPanelDisplay = ref({ userDisplayName: null, actionReportStatusDisplayName: null });
const appliedFilter = ref(new Object());

const filtersCount = computed(() => {
  let count = 0;

  for (const property in appliedFilter.value) {
    if (appliedFilter.value[property] != null) count++;
  }

  return count;
});

const userApplied = computed(() => {
  return filterApplied(filterPanel.value?.user?.id, appliedFilter.value?.user?.id);
});

const actionReportStatusApplied = computed(() => {
  return filterApplied(
    filterPanel.value?.actionReportStatus?.id,
    appliedFilter.value?.actionReportStatus?.id
  );
});

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.user = data;
      filterPanelDisplay.value.userDisplayName =
        (data?.firstName ?? '') + ' ' + (data?.lastName ?? '');
      break;
    case MODAL_ACTIONS_REPORT_STATUSES_TABLE:
      filterPanel.value.actionReportStatus = data;
      filterPanelDisplay.value.actionReportStatusDisplayName = data?.name ?? '';
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.user = null;
      filterPanelDisplay.value.userDisplayName = null;
      break;
    case MODAL_ACTIONS_REPORT_STATUSES_TABLE:
      filterPanel.value.actionReportStatus = null;
      filterPanelDisplay.value.actionReportStatusDisplayName = null;
      break;
  }
}

function onClearAllFiltersButtonClicked() {
  for (const property in filterPanel.value) {
    filterPanel.value[property] = null;
  }

  for (const property in filterPanelDisplay.value) {
    filterPanelDisplay.value[property] = null;
  }

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (filterPanel.value?.user?.id != null) userFilter.id_author = filterPanel.value.user.id;

  if (filterPanel.value?.actionReportStatus?.id != null)
    userFilter.id_register_raport_status = filterPanel.value.actionReportStatus.id;

  return userFilter;
}

function updateAppliedFilters() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchBox.value,
    filter: getUserFilter()
  });
}

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

onMounted(() => {
  configureCollapsingFilterPanel();
});

defineExpose({ updateAppliedFilters });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row">
            <div v-if="props.userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry &nbsp;
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="props.showSearchBar"
              :class="
                props.longerSearchBar
                  ? 'col-sm-6 ' + (props.userFilterEnabled ? 'offset-sm-0' : 'offset-sm-6')
                  : 'col-sm-3 ' + (props.userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content mt-3" style="display: none">
            <div class="row">
              <div class="col-sm-4">
                <ModalInputField
                  title="Status raportu"
                  :titleCssClass="actionReportStatusApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.actionReportStatusDisplayName"
                  @showModal="modalTable.showModal(MODAL_ACTIONS_REPORT_STATUSES_TABLE)"
                />
              </div>
              <div class="col-sm-4">
                <ModalInputField
                  title="Użytkownik"
                  :titleCssClass="userApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.userDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: 'user/administrators' }
                      }
                    })
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
