<script setup>
import { computed } from 'vue';
import ChartPanel from './ChartPanel.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { monthsXLabels } from '../../helpers/dashboardHelper';

const props = defineProps({
  header: { type: String, default: null },
  graphData: { type: Array, default: () => [] },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

var graphOptions = {
  xaxis: {
    ticks: monthsXLabels,
    autoscaleMargin: 0.05
  },
  yaxes: [{ position: 'left' }, { position: 'right' }],
  grid: {
    color: '#bababa',
    hoverable: true,
    clickable: true,
    borderWidth: 0
  },
  legend: { show: true, position: 'nw' },
  tooltip: true,
  tooltipOpts: { content: 'Zlecenia: %y' }
};

const dataAvailable = computed(() => isNotEmptyArray(props.graphData));

const graphDatasets = computed(() => {
  if (!dataAvailable.value) return null;

  return [
    {
      label: 'Założone zlecenia',
      data: props.graphData.map((item) => [item.month, item.order_sum]),
      color: '#1ab394',
      bars: {
        show: true,
        horizontal: false,
        barWidth: 0.4,
        align: 'center'
      }
    },
    {
      label: '% udanych demontaży)',
      data: props.graphData.map((item) => [
        item.month,
        getEfficiency(item.dem_points_done, item.failures)
      ]),
      yaxis: 2,
      color: '#1C84C6',
      splines: {
        show: true,
        tension: 0.3,
        lineWidth: 1,
        fill: 0.1
      }
    }
  ];
});

function getEfficiency(demPointsDone, failures) {
  const sum = demPointsDone + failures;

  if (sum === 0) return 0;

  return (demPointsDone / (demPointsDone + failures)) * 100;
}

function onAfterPlotInitialized(plot) {
  var ctx = plot.getCanvas().getContext('2d');
  const series = plot.getData();
  const axes = plot.getAxes();

  ctx.font = '14px Arial';
  ctx.fillStyle = 'grey';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'center';

  ctx.fillStyle = '#1ab394';
  series[0].data.forEach(function (point) {
    const x = plot.getPlotOffset().left + axes.xaxis.p2c(point[0]);
    const y = plot.getPlotOffset().top + axes.yaxis.p2c(point[1]);
    ctx.fillText(point[1].toFixed(), x, y - 10);
  });

  ctx.fillStyle = '#1C84C6';
  series[1].data.forEach(function (point) {
    const x = plot.getPlotOffset().left + axes.xaxis.p2c(point[0]);
    const y = plot.getPlotOffset().top + axes.y2axis.p2c(point[1]);
    ctx.fillText(point[1].toFixed() + '%', x, y - 10);
  });
}
</script>

<template>
  <ChartPanel
    :header="props.header"
    :stats="null"
    :graphDatasets="graphDatasets"
    :graphOptions="graphOptions"
    :afterInitializeAction="onAfterPlotInitialized"
    :rightHeaderText="props.rightHeaderText"
    :isLoading="props.isLoading"
  />
</template>

<style scoped></style>
