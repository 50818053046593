import { ref } from 'vue';
import { bringMarkerToFront, getMarker, resetZIndex } from '../../helpers/markersCreator';
import L from 'leaflet';
import 'leaflet.markercluster';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

export function useFeatureGroups(map, features, markersRef) {
  const SELECTED_FEATURE_GROUP_KEY = 'selectedFeatureGroup';
  const featureGroups = ref([]);
  const temporarilyDetachedMarkers = ref(new Set()); // the markers that are detached from their original feature groups, they're temporarily stored here and placed in the selectedFeatureGroup

  function getFeatureGroupWithKey(key) {
    return featureGroups.value.find((group) => group.key === key)?.group;
  }

  function createEmptyFeatureGroup(featureGroupKey) {
    const newFeatureGroup = L.featureGroup();
    features.value.leafletObject.addLayer(newFeatureGroup);
    featureGroups.value.push({ key: featureGroupKey, group: newFeatureGroup });

    return newFeatureGroup;
  }

  function createEmptyClusterGroup(featureGroupKey, clusterSettings = undefined) {
    const newClusterGroup = L.markerClusterGroup(clusterSettings);
    features.value.leafletObject.addLayer(newClusterGroup);
    featureGroups.value.push({ key: featureGroupKey, group: newClusterGroup });

    return newClusterGroup;
  }

  /*
    Creates a marker and adds it to the feature group (can be cluster group).
    IMPORTANT: If group with given key does not exist, it is created. If you want to use cluster group, create it earlier with createEmptyClusterGroup() function and pass its key here.
  */
  function createAdditionalMarker(featureGroupKey, markerData, onMarkerClick, onMarkerMoveEnd) {
    markerData.featureGroupKey = featureGroupKey;
    const newMarker = getMarker(markerData, onMarkerClick, onMarkerMoveEnd);
    const foundFeatureGroup = getFeatureGroupWithKey(featureGroupKey);

    if (foundFeatureGroup) {
      foundFeatureGroup.addLayer(newMarker);
    } else {
      const newFeatureGroup = createEmptyFeatureGroup(featureGroupKey);
      newFeatureGroup.addLayer(newMarker);
    }

    return newMarker;
  }

  /*
    Creates a marker with connection and adds it to the feature group (can be cluster group).
    IMPORTANT: If group with given key does not exist, it is created. If you want to use cluster group, create it earlier with createEmptyClusterGroup() function and pass its key here.
  */
  function createAdditionalMarkerWithConnection(
    featureGroupKey,
    markerData,
    onMarkerClick,
    onMarkerMoveEnd,
    markerToConnectTo,
    fitConnectionBounds,
    lineColor = 'red',
    showLineDelayMilis = null // if set, the polyline will appear after given delay in miliseconds
  ) {
    const additionalMarker = createAdditionalMarker(
      featureGroupKey,
      markerData,
      onMarkerClick,
      onMarkerMoveEnd
    );
    const polyline = getPolylineBetweenMarkers(additionalMarker, markerToConnectTo, lineColor);

    if (showLineDelayMilis != null) {
      polyline.setStyle({ opacity: 0 });
      setTimeout(() => polyline.setStyle({ opacity: 1 }), showLineDelayMilis);
    }

    getFeatureGroupWithKey(featureGroupKey).addLayer(polyline);

    if (fitConnectionBounds) {
      map.value.leafletObject.fitBounds(polyline.getBounds());
    }

    return additionalMarker;
  }

  // returns true if removed
  function removeFeatureGroupWithKey(featureGroupKey) {
    const foundFeatureGroup = getFeatureGroupWithKey(featureGroupKey);

    if (foundFeatureGroup) {
      const markersToReattach = Array.from(temporarilyDetachedMarkers.value).filter(
        (marker) => marker.data.featureGroupKey === featureGroupKey
      );

      markersToReattach.forEach((marker) => attachMarker(marker));

      markersRef.value = markersRef.value.filter(
        (marker) => marker.data.featureGroupKey !== featureGroupKey
      );
      foundFeatureGroup.clearLayers();
      features.value.leafletObject.removeLayer(foundFeatureGroup);
      const index = featureGroups.value.findIndex((group) => group.key === featureGroupKey);
      featureGroups.value.splice(index, 1);
      return true;
    }

    return false;
  }

  function getPolylineBetweenMarkers(marker1, marker2, lineColor = 'red') {
    if (marker1?.data?.lng == null || marker2?.data?.lng == null) return;

    const latlngs = [
      [marker1.data.lat, marker1.data.lng],
      [marker2.data.lat, marker2.data.lng]
    ];

    return L.polyline(latlngs, { color: lineColor, weight: 2 });
  }

  function removeAllFeatureGroups() {
    if (!isNotEmptyArray(featureGroups.value)) return;

    temporarilyDetachedMarkers.value.clear();

    featureGroups.value.forEach((featureGroup) => {
      features.value.leafletObject.removeLayer(featureGroup.group);
    });

    featureGroups.value = [];
  }

  /**
   * Detaches a marker from its feature group and adds it to the main features layer.
   * If the marker is already detached (present in temporarilyDetachedMarkers), the function returns early.
   * @param {L.Marker} marker - The Leaflet marker to be detached
   * @returns {void}
   */
  function detachMarker(marker) {
    if (temporarilyDetachedMarkers.value.has(marker)) return;

    const featureGroupKey = marker.data.featureGroupKey;
    const featureGroup = getFeatureGroupWithKey(featureGroupKey);

    featureGroup.removeLayer(marker);
    addMarkerToSelectedFeatureGroup(marker);
    temporarilyDetachedMarkers.value.add(marker);
  }

  /**
   * Reattaches a temporarily detached marker to its original feature group.
   * @param {L.Marker} marker - The marker to be reattached
   * @returns {void}
   */
  function attachMarker(marker) {
    if (!temporarilyDetachedMarkers.value.has(marker)) return;

    const featureGroupKey = marker.data.featureGroupKey;
    const featureGroup = getFeatureGroupWithKey(featureGroupKey);

    removeMarkerFromSelectedFeatureGroup(marker);
    featureGroup.addLayer(marker);
    temporarilyDetachedMarkers.value.delete(marker);
  }

  function regroupSelectedMarkers() {
    if (!isNotEmptyArray(markersRef.value)) return;

    const toDetach = new Set();
    const toAttach = new Set();
    const openPopups = new Set();

    markersRef.value.forEach((marker) => {
      const selected = marker.data.selected;
      const isDetached = temporarilyDetachedMarkers.value.has(marker);

      if (selected && !isDetached) {
        toDetach.add(marker);
      } else if (!selected && isDetached) {
        toAttach.add(marker);
      }

      if (marker.getPopup() && marker.getPopup().isOpen()) {
        openPopups.add(marker);
      }
    });

    toAttach.forEach((marker) => {
      attachMarker(marker);
      resetZIndex(marker);
    });

    toDetach.forEach((marker) => {
      detachMarker(marker);
      bringMarkerToFront(marker);
    });

    openPopups.forEach((marker) => marker.openPopup());
  }

  function addMarkerToSelectedFeatureGroup(marker) {
    const selectedFeatureGroup = getFeatureGroupWithKey(SELECTED_FEATURE_GROUP_KEY);

    if (selectedFeatureGroup) {
      selectedFeatureGroup.addLayer(marker);
    } else {
      const newFeatureGroup = createEmptyFeatureGroup(SELECTED_FEATURE_GROUP_KEY);
      newFeatureGroup.addLayer(marker);
    }
  }

  function removeMarkerFromSelectedFeatureGroup(marker) {
    const selectedFeatureGroup = getFeatureGroupWithKey(SELECTED_FEATURE_GROUP_KEY);

    if (selectedFeatureGroup) {
      selectedFeatureGroup.removeLayer(marker);
    }
  }

  return {
    getFeatureGroupWithKey,
    createAdditionalMarker,
    removeFeatureGroupWithKey,
    createAdditionalMarkerWithConnection,
    removeAllFeatureGroups,
    createEmptyFeatureGroup,
    createEmptyClusterGroup,
    regroupSelectedMarkers
  };
}
