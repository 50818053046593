<script setup>
/* eslint-env jquery */
import { ref, computed, onMounted } from 'vue';
import ModalTable from '@/components/utils/ModalTable.vue';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  onModalCompletedAction: { type: Function, default: () => {} },
  onModalRejectedAction: { type: Function, default: () => {} },
  getUserFilterOverride: { type: Function, default: () => {} },
  showSearchBar: { type: Boolean, default: true }
});
const emit = defineEmits(['fetchData']);
const modalTable = ref();
const searchBox = ref('');
const filterPanel = ref({});
const filterPanelDisplay = ref({});
const appliedFilter = ref(new Object());
const showCloseButton = ref(false);

const filtersCount = computed(() => {
  let count = 0;

  for (const property in appliedFilter.value) {
    if (appliedFilter.value[property] != null) count++;
  }

  return count;
});

function onModalCompleted(data, data2, modalProperties) {
  if (!modalProperties?.multiSelections) {
    modalTable.value.hide();
  }

  props.onModalCompletedAction(modalTable.value.modal.type, data, data2, modalProperties);
}

function onModalRejected() {
  modalTable.value.hide();
  props.onModalRejectedAction(modalTable.value.modal.type);
}

function onClearAllFiltersButtonClicked() {
  for (const property in filterPanel.value) {
    filterPanel.value[property] = null;
  }

  for (const property in filterPanelDisplay.value) {
    filterPanelDisplay.value[property] = null;
  }

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function updateAppliedFilters() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  return appliedFilterProp && filterPanelProp
    ? appliedFilterProp === filterPanelProp
    : appliedFilterProp == filterPanelProp;
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchBox.value,
    filter: props.getUserFilterOverride()
  });
}

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function showModal(tableKey, payload) {
  showCloseButton.value = payload?.additionalProps?.multiSelections === true;

  modalTable.value.showModal(tableKey, payload);
}

function setSearchBoxValue(newValue) {
  searchBox.value = newValue;
}

onMounted(() => {
  configureCollapsingFilterPanel();
});

defineExpose({
  updateAppliedFilters,
  filterApplied,
  appliedFilter,
  showModal,
  filterPanel,
  filterPanelDisplay,
  setSearchBoxValue
});
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="props.userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry &nbsp;
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="props.showSearchBar"
              :class="
                props.longerSearchBar
                  ? 'col-sm-6 ' + (props.userFilterEnabled ? 'offset-sm-0' : 'offset-sm-6')
                  : 'col-sm-3 ' + (props.userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <slot></slot>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
      :showCloseButton="showCloseButton"
      overrideCloseButtonText="Zatwierdź wybór"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
