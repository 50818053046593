<script setup>
/* eslint-env jquery */
import { computed, watch, onMounted } from 'vue';
import { generateRandomString, isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  header: { type: String, default: null },
  graphDataset: { type: Array, default: () => [] },
  graphOptions: { type: Object, default: null },
  afterInitializeAction: { type: Function, default: null },
  legentDivId: { type: String, default: null },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

const componentId = generateRandomString(20);

const dataAvailable = computed(() => isNotEmptyArray(props.graphDataset));

watch([() => props.graphDataset, () => props.graphOptions], () => {
  initializeGraph();
});

function initializeGraph() {
  if (!dataAvailable.value) return;

  const plot = $.plot($(`#${componentId}`), props.graphDataset, props.graphOptions);

  if (props.afterInitializeAction) {
    props.afterInitializeAction(plot);
  }
}

onMounted(() => {
  initializeGraph();
});
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>{{ props.header }}</h5>
      <div class="ibox-tools">
        {{ props.rightHeaderText }}
      </div>
    </div>

    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div v-show="dataAvailable" style="display: flex; flex-direction: row; gap: 20px">
        <div class="flot-chart">
          <div class="flot-chart-pie-content" :id="componentId"></div>
        </div>

        <div v-if="props.legentDivId != null" :id="props.legentDivId"></div>
      </div>

      <div v-show="!dataAvailable">
        <p class="text-center">Brak danych do wyświetlenia</p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
