import {
  ACTION_FROM_DEM_POINT_MARKER_TYPE_ID,
  ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID,
  DEM_POINT_MARKER_TYPE_ID,
  SINGLE_DEM_POINT_TYPE_ID,
  START_ORDER_ACTION_POPUP_CAPTION,
  STOP_ORDER_ACTION_POPUP_CAPTION
} from '../../data/constants/appConstants.js';

import { isNonEmptyString, isNotEmptyArray, pushSafe } from '../utilsHelper';
import { getDivIconForDemPointMarker } from '../demPointMarkerHelper';
import { getCreatedActionMarkerTooltip } from '../tooltips/actionMarkersTooltipsHelper.js';
import { getCreatedDemPointMarkerTooltip } from '../tooltips/demPointMarkersTooltipsHelper.js';
import { DEVICE_LOCATION_SHOW_MIN_DIFFERENCE_METERS } from '../../data/projectConfig.js';

export function getDemPointsForMapMarkers(
  demPoints,
  disabledActionTypesIds,
  disabledFailureReasonsIds,
  disabledAdditionalDismantlingSourcesIds,
  disabledUserOrdersIds,
  showOnlyLastActionsFilterEnabled,
  showInactiveDemPointsFilter,
  addTooltip = true,
  selectingBlocked = false,
  markersClassName = '',
  showActionIfHasAny = true,
  authStore = null,
  hideOperationalButtons = false,
  optionals = null
) {
  if (!isNotEmptyArray(demPoints)) return [];

  const markers = new Array();

  demPoints.forEach((demPoint) => {
    // Skip inactive demPoints if showInactiveDemPointsFilter is false
    if (!showInactiveDemPointsFilter && demPoint.add_action_allowed === 0) return;

    let marker = null;
    let actionTypeId = null;
    let userOrderId = null;

    if (isNotEmptyArray(demPoint?.actions) && showActionIfHasAny) {
      let actionToDisplay = getDemPointActionToDisplay(
        demPoint,
        disabledActionTypesIds,
        disabledFailureReasonsIds,
        disabledAdditionalDismantlingSourcesIds,
        disabledUserOrdersIds
      );

      if (
        actionToDisplay != null &&
        showOnlyLastActionsFilterEnabled &&
        !isMostRecentAction(demPoint.actions, actionToDisplay)
      ) {
        actionToDisplay = null;
      }

      let isOSD = demPoint?.dem_elem_type?.id === 6;

      marker = actionToDisplay
        ? getConstructedMarkerForAction(
            actionToDisplay,
            isOSD,
            demPoint.lat,
            demPoint.lon,
            addTooltip,
            markersClassName,
            authStore,
            demPoint,
            hideOperationalButtons,
            optionals?.selectableActionsOverride,
            optionals?.showActionsBrowser ?? false
          )
        : null;
      actionTypeId = actionToDisplay?.action_type?.id ?? null;
      userOrderId = actionToDisplay?.id_user_order ?? null;
    } else {
      marker = getConstructedMarkerForDemPoint(
        demPoint,
        addTooltip,
        authStore,
        hideOperationalButtons,
        selectingBlocked,
        markersClassName,
        optionals?.selectableDemPointsOverride
      );
    }

    if (
      marker &&
      !disabledActionTypesIds.includes(actionTypeId) &&
      !disabledUserOrdersIds.includes(userOrderId)
    ) {
      markers.push(marker);
    }
  });

  return markers;
}

function getDemPointActionToDisplay(
  demPoint,
  disabledActionTypesIds,
  disabledFailureReasonsIds,
  disabledAdditionalDismantlingSourcesIds,
  disabledUserOrdersIds
) {
  return demPoint.actions.find(
    (action) =>
      action.map_display === 1 &&
      !disabledActionTypesIds.includes(action.action_type?.id) &&
      !disabledUserOrdersIds.includes(action?.id_user_order) &&
      (action.failure_reason == null ||
        !disabledFailureReasonsIds.includes(action.failure_reason.id)) &&
      (action.extra_dem_source == null ||
        !disabledAdditionalDismantlingSourcesIds.includes(action.extra_dem_source.id))
  );
}

function isMostRecentAction(demPointActions, action) {
  if (!isNotEmptyArray(demPointActions)) return false;
  if (action == null) return false;
  if (demPointActions.length === 1) return true;

  const actionDate = new Date(action.geotimestamp.date);
  let isMostRecent = true;

  for (let i = 0; i < demPointActions.length; i++) {
    const currentAction = demPointActions[i];
    const currentDate = new Date(currentAction.geotimestamp.date);

    if (currentDate > actionDate) {
      isMostRecent = false;
      break;
    }
  }

  return isMostRecent;
}

function getConstructedMarkerForDemPoint(
  demPoint,
  addTooltip,
  authStore,
  hideOperationalButtons,
  selectingBlocked = false,
  markersClassName = '',
  selectableOverride = null
) {
  const lat = demPoint.lat;
  const lon = demPoint.lon;

  let tooltip = addTooltip
    ? getCreatedDemPointMarkerTooltip(lat, lon, demPoint, authStore, hideOperationalButtons)
    : null;

  let marker = {
    lat: lat,
    lng: lon,
    popupCaption: tooltip,
    draggable: false,
    selected: false,
    selectable:
      selectableOverride != null
        ? selectableOverride
        : demPoint?.dem_point_type?.id === SINGLE_DEM_POINT_TYPE_ID && !selectingBlocked,
    markerId: demPoint.id,
    demPointTag: demPoint.oznaczenie,
    markerType: DEM_POINT_MARKER_TYPE_ID,
    add_action_allowed: demPoint.add_action_allowed,
    demPointTypeId: demPoint.dem_point_type.id
  };

  marker = Object.assign(marker, getDivIconForDemPointMarker(demPoint, markersClassName));

  if (demPoint.osd_poles > 0) {
    const width = marker.divIcon?.iconSize?.[0] ?? marker.iconSize[0];
    const height = marker.divIcon?.iconSize?.[1] ?? marker.iconSize[1];

    marker.shadows = pushSafe(marker.shadows, {
      url: 'OSD_underline',
      size: [26, 5],
      topPx: height + 2,
      leftPx: width / 2 - 13
    });
  }

  if (isNonEmptyString(demPoint?.note)) {
    let shadowSize = [5.25, 15.375];

    marker.shadows = pushSafe(marker.shadows, {
      url: 'exclamation_shadow_grey',
      size: shadowSize,
      topPx: -0.5 * shadowSize[1],
      leftPx: 0 - shadowSize[0] + 2
    });
  }

  return marker;
}

function getConstructedMarkerForAction(
  action,
  isOSD,
  lat,
  lon,
  addTooltip,
  markerClassName = '',
  authStore = null,
  demPoint,
  hideOperationalButtons = false,
  selectableOverride = null,
  showActionsBrowserIfDemPoint = false
) {
  if (!action?.map_display) return null;

  const popupCaption = addTooltip
    ? getCreatedActionMarkerTooltip(
        lat,
        lon,
        action,
        demPoint,
        authStore,
        hideOperationalButtons,
        showActionsBrowserIfDemPoint
      )
    : null;

  let marker = {
    markerId: createActionMarkerId(action.id),
    lat,
    lng: lon,
    popupCaption,
    draggable: false,
    icon: undefined,
    iconSize: undefined,
    iconAnchor: undefined,
    selectable: selectableOverride != null ? selectableOverride : false,
    className: markerClassName,
    groupId: action.id_group,
    actionId: action.id,
    demPointId: demPoint != null ? demPoint.id : undefined,
    markerType:
      demPoint == null
        ? ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID
        : ACTION_FROM_DEM_POINT_MARKER_TYPE_ID,
    add_action_allowed:
      demPoint?.add_action_allowed != null ? demPoint?.add_action_allowed : undefined,
    demPointTypeId: demPoint?.dem_point_type?.id != null ? demPoint.dem_point_type.id : undefined,
    deviceLocation: {
      lat: action.geotimestamp.lat_d,
      lon: action.geotimestamp.lon_d,
      difference: action.geotimestamp.difference
    }
  };

  assignActionMarkerIcon(
    marker,
    action.action_type.id,
    isOSD,
    action?.failure_reason?.id,
    demPoint == null
  );

  if (demPoint?.osd_poles != null && demPoint.osd_poles > 0) {
    marker.shadows = pushSafe(marker.shadows, {
      url: 'OSD_underline',
      size: [26, 5],
      topPx: marker.iconSize[1] + 2,
      leftPx: marker.iconSize[0] / 2 - 13
    });
  }

  if (demPoint?.add_action_allowed === 0) {
    marker.shadows = pushSafe(marker.shadows, {
      url: 'small_black_x',
      size: [9.5, 9],
      topPx: 0,
      leftPx: marker.iconSize[0] - 2
    });
  }

  if (isNonEmptyString(demPoint?.note)) {
    let shadowSize = [5.25, 15.375];

    marker.shadows = pushSafe(marker.shadows, {
      url: 'exclamation_shadow_grey',
      size: shadowSize,
      topPx: -0.5 * shadowSize[1],
      leftPx: 0 - shadowSize[0] + 2
    });
  }

  return marker;
}

function assignActionMarkerIcon(
  marker,
  actionTypeId,
  isOSD,
  dismantlingFailureReasonId,
  directlyOnMap
) {
  marker.iconSize = [33, 48];
  marker.iconAnchor = [16, 48];

  switch (actionTypeId) {
    case 1:
      marker.icon = isOSD ? 'action_marker_mechanic_osd' : 'action_marker_mechanic';
      break;
    case 2:
      marker.icon = isOSD ? 'action_marker_fist_osd' : 'action_marker_fist';
      break;
    case 3:
      if (dismantlingFailureReasonId != null) {
        marker.icon = getActionMarkerIconByDismantlingFailureReason(dismantlingFailureReasonId);
        marker.iconSize = [30, 30];
        marker.iconAnchor = [15, 15];
      } else {
        marker.icon = 'action_marker_red_x';
      }
      break;
    case 4:
      marker.icon = 'action_marker_transport';
      break;
    case 5:
      marker.icon = isOSD ? 'action_marker_mechanic_plus_osd' : 'action_marker_mechanic_plus';
      break;
    case 6:
      marker.icon = isOSD ? 'action_marker_fist_plus_osd' : 'action_marker_fist_plus';
      break;
    case 7:
      marker.icon = directlyOnMap ? 'action_marker_pencil_plus' : 'action_marker_pencil';
      break;
    case 8:
      marker.icon = 'action_marker_cube';
      break;
    case 9:
      marker.icon = 'confirmed_for_disassembly';
      marker.iconSize = [30, 30];
      marker.iconAnchor = [15, 15];
      break;
  }
}

function getActionMarkerIconByDismantlingFailureReason(dismantlingFailureReasonId) {
  switch (dismantlingFailureReasonId) {
    case 1:
      return 'POR_green';
    case 2:
      return 'grain';
    case 3:
      return 'water';
    case 4:
      return 'phone_crossed_out';
    case 5:
      return 'tree_v2';
    case 6:
      return 'active_pole';
    case 7:
      return 'hammer';
    case 8:
      return 'red_question_mark';
    case 9:
      return 'no_pole_marker';
    case 10:
      return 'declaration_of_disagreement';
    case 11:
      return 'rechecking';
    case 12:
      return 'third_party_operator';
  }
}

export function getActionsWithNoDemPointForMapMarkers(
  actionsWithNoDemPoint,
  disabledActionTypesIds,
  disabledFailureReasonsIds,
  disabledAdditionalDismantlingSourcesIds,
  disabledUserOrdersIds,
  addTooltip = true,
  markersClassName = '',
  authStore = null,
  hideOperationalButtons = false,
  optionals = null
) {
  if (!isNotEmptyArray(actionsWithNoDemPoint)) return [];
  const markers = new Array();

  actionsWithNoDemPoint.forEach((action) => {
    let marker = getConstructedMarkerForAction(
      action,
      action.osd_detail != null,
      action.geotimestamp.lat,
      action.geotimestamp.lon,
      addTooltip,
      markersClassName,
      authStore,
      null,
      hideOperationalButtons,
      optionals?.selectableActionsOverride,
      false
    );

    if (
      marker &&
      !disabledActionTypesIds.includes(action.action_type.id) &&
      !disabledUserOrdersIds.includes(action?.id_user_order) &&
      (action.failure_reason == null ||
        !disabledFailureReasonsIds.includes(action.failure_reason.id)) &&
      (action.extra_dem_source == null ||
        !disabledAdditionalDismantlingSourcesIds.includes(action.extra_dem_source.id))
    ) {
      markers.push(marker);
    }
  });

  return markers;
}

export function getWorkersStartStopMarkers(workersStartStopData, enabledUserOrdersStartStopsIds) {
  if (!isNotEmptyArray(workersStartStopData)) return [];

  const markers = new Array();

  workersStartStopData.forEach((workerStartStopData) => {
    if (!enabledUserOrdersStartStopsIds.includes(workerStartStopData.id)) return;
    let startStopMarkers = getStartStopMarkersDataForOrderWorker(workerStartStopData);
    markers.push(...startStopMarkers);
  });

  return markers;
}

function getStartStopMarkersDataForOrderWorker(orderWorkerData) {
  let result = [];

  if (orderWorkerData.start) {
    let startMarker = {
      markerId: 'start',
      lat: orderWorkerData.start.lat,
      lng: orderWorkerData.start.lon,
      popupCaption: START_ORDER_ACTION_POPUP_CAPTION,
      draggable: false,
      icon: 'start_marker',
      iconSize: [25, 38],
      iconAnchor: [6, 38],
      selectable: false
    };

    result.push(startMarker);
  }

  if (orderWorkerData.stop) {
    let stopMarker = {
      markerId: 'stop',
      lat: orderWorkerData.stop.lat,
      lng: orderWorkerData.stop.lon,
      popupCaption: STOP_ORDER_ACTION_POPUP_CAPTION,
      draggable: false,
      icon: 'finish_marker',
      iconSize: [25, 38],
      iconAnchor: [6, 38],
      selectable: false
    };

    result.push(stopMarker);
  }

  return result;
}

export function addGroupsCountInfoToActions(demPoints, actionsWithNoDemPoint) {
  const actionGroupsDictionary = {};

  // demPoints added to groups dictionary
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null) {
            if (Object.prototype.hasOwnProperty.call(actionGroupsDictionary, action.id_group)) {
              actionGroupsDictionary[action.id_group]++;
            } else {
              actionGroupsDictionary[action.id_group] = 1;
            }
          }
        });
      }
    });
  }

  // actions with no demPoint added to groups dictionary
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null) {
        if (Object.prototype.hasOwnProperty.call(actionGroupsDictionary, action.id_group)) {
          actionGroupsDictionary[action.id_group]++;
        } else {
          actionGroupsDictionary[action.id_group] = 1;
        }
      }
    });
  }

  // based on actionGroupsDictionary, added groupCount to each action from demPoints
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null) {
            action.groupCount = actionGroupsDictionary[action.id_group];
          }
        });
      }
    });
  }

  // based on actionGroupsDictionary, added groupCount to each action from actions with no dem points array
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null) {
        action.groupCount = actionGroupsDictionary[action.id_group];
      }
    });
  }
}

export function getActionGroupMembers(groupId, demPoints, actionsWithNoDemPoint) {
  const members = [];

  // demPoints
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null && action.id_group === groupId) {
            action.dem_point = {
              id: demPoint.id,
              lat: demPoint.lat,
              lon: demPoint.lon
            };
            members.push(action);
          }
        });
      }
    });
  }

  // actions with no demPoint
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null && action.id_group === groupId) {
        members.push(action);
      }
    });
  }

  return members;
}

export function createActionMarkerId(actionId) {
  return 'action_' + actionId;
}

export function canCreateDeviceLocationMarkerForAction(action) {
  if (action?.geotimestamp?.lat_d == null) return false;
  if (action?.geotimestamp?.lon_d == null) return false;
  if (action?.geotimestamp?.difference == null) return false;
  if (action?.geotimestamp?.difference < DEVICE_LOCATION_SHOW_MIN_DIFFERENCE_METERS) return false;

  return true;
}

export function canCreateDeviceLocationMarkerForActionMarker(actionMarker) {
  if (actionMarker?.data?.deviceLocation?.lat == null) return false;
  if (actionMarker?.data?.deviceLocation?.lon == null) return false;
  if (actionMarker?.data?.deviceLocation?.difference == null) return false;
  if (actionMarker?.data?.deviceLocation?.difference < DEVICE_LOCATION_SHOW_MIN_DIFFERENCE_METERS)
    return false;

  return true;
}

export function canCreatePhotosMarkersForAction(action) {
  return action?.resources_count > 0;
}
