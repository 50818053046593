<script setup>
import { computed } from 'vue';
import PieChart from './PieChart.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  data: { type: Object, default: null },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

const pieChartData = computed(() => {
  if (!isNotEmptyArray(props.data?.dem_points_done?.data)) return null;
  if (!isNotEmptyArray(props.data?.users_failures?.data)) return null;

  const demPointsDoneTotal = props.data.dem_points_done.data.reduce(
    (acc, item) => acc + item.total,
    0
  );
  const usersFailuresTotal = props.data.users_failures.data.reduce(
    (acc, item) => acc + item.total,
    0
  );
  const sum = demPointsDoneTotal + usersFailuresTotal;

  if (sum === 0) return null;

  const demPointsDoneLabel =
    props.data.dem_points_done.title + ` (${((demPointsDoneTotal / sum) * 100).toFixed(1)}%)`;

  const usersFailuresLabel =
    props.data.users_failures.title + ` (${((usersFailuresTotal / sum) * 100).toFixed(1)}%)`;

  const chartData = [
    {
      label: demPointsDoneLabel,
      data: demPointsDoneTotal,
      color: '#79d2c0'
    },
    {
      label: usersFailuresLabel,
      data: usersFailuresTotal,
      color: '#EB9486'
    }
  ];

  chartData.sort((a, b) => {
    return a.data === b.data ? 0 : a.data < b.data ? 1 : -1;
  });

  return chartData;
});

const graphOptions = {
  series: {
    pie: {
      show: true,
      innerRadius: 0.5
    }
  },
  grid: {
    hoverable: true
  },
  tooltip: true,
  tooltipOpts: {
    content: '%s',
    shifts: { x: 20, y: 0 },
    defaultTheme: false
  },
  legend: {
    show: true,
    container: '#fsgdsfgdfgdfg'
  }
};
</script>

<template>
  <PieChart
    header="Demontaże i niepowodzenia"
    :graphDataset="pieChartData"
    :graphOptions="graphOptions"
    :rightHeaderText="props.rightHeaderText"
    legentDivId="fsgdsfgdfgdfg"
    :isLoading="props.isLoading"
  />
</template>

<style scoped></style>
