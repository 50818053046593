import { ref } from 'vue';
import { ACTION_PHOTO_MARKER_TYPE_ID } from '../../data/constants/appConstants';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  ACTION_PHOTO_BASIC_CONNECTION_LINE_COLOR,
  ACTION_PHOTO_METADATA_CONNECTION_LINE_COLOR,
  DEVICE_LOCATION_CONNECTION_LINE_COLOR
} from '../../data/projectConfig';
import { canCreateDeviceLocationMarkerForActionMarker } from '../../helpers/actions/actionMarkersHelper';

const ADDITIONAL_ACTION_MARKERS_FEATURE_GROUP_KEY_PREFIX = 'add_act_mark_';
const DEVICE_LOCATION_MARKER_ID_PREFIX = 'dev_loc_';
const ACTION_PHOTO_MARKER_ID_PREFIX = 'action_photo_';

export function useActionsAdditionalMarkers(clusterMap) {
  const RepositoryActions = RepositoryFactory.get('actions');
  const additionalMarkersActionMarkersIds = ref([]); // ids of action markers that have their additional markers created

  async function tryToShowAdditionalMarkers(actionMarker) {
    if (actionMarker?.data?.markerId == null) {
      console.warn("tryToShowAdditionalMarkers(): You're trying to reference null markerId.");
      return;
    }

    const actionMarkerId = actionMarker.data.markerId;
    const featureGroupKey = getFeatureGroupKey(actionMarkerId);
    clusterMap.value.createEmptyClusterGroup(featureGroupKey);
    const newMarkers = [];

    // device location
    const deviceLocationMarker = tryToCreateDeviceLocationMarker(actionMarker, featureGroupKey);
    if (deviceLocationMarker) newMarkers.push(deviceLocationMarker);

    // photos
    const photosMarkers = await tryToCreatePhotosMarkers(actionMarker, featureGroupKey);
    if (isNotEmptyArray(photosMarkers)) newMarkers.push(...photosMarkers);

    if (isNotEmptyArray(newMarkers)) {
      additionalMarkersActionMarkersIds.value.push(actionMarkerId);
    }
  }

  function tryToCreateDeviceLocationMarker(actionMarker, featureGroupKey) {
    if (!canCreateDeviceLocationMarkerForActionMarker(actionMarker)) return null;

    const deviceLocationMarkerData = getDeviceLocationMarkerData(
      actionMarker.data.markerId,
      actionMarker.data.deviceLocation.lat,
      actionMarker.data.deviceLocation.lon
    );

    const newMarker = clusterMap.value.addConnectedMarker(
      featureGroupKey,
      deviceLocationMarkerData,
      actionMarker,
      false,
      DEVICE_LOCATION_CONNECTION_LINE_COLOR,
      50
    );

    return newMarker;
  }

  function getDeviceLocationMarkerData(actionMarkerId, lat, lon) {
    const markerId = DEVICE_LOCATION_MARKER_ID_PREFIX + actionMarkerId;
    const icon = 'device_location_marker';
    const size = [14.8, 30];
    const anchor = [7.4, 30];

    return {
      markerId,
      lat,
      lng: lon,
      draggable: false,
      selected: false,
      selectable: false,
      popupCaption: null,
      zIndexOffset: 1000,
      divIcon: {
        className: '',
        iconSize: size,
        iconAnchor: anchor,
        html: `<img src='/static/img/markerIcons/${icon}.png' style='position: absolute; top: 0; left: 0; width: ${size[0]}px; height: ${size[1]}px;'/>`
      }
    };
  }

  function getFeatureGroupKey(actionMarkerId) {
    return ADDITIONAL_ACTION_MARKERS_FEATURE_GROUP_KEY_PREFIX + actionMarkerId;
  }

  async function tryToCreatePhotosMarkers(actionMarker, featureGroupKey) {
    const actionId = actionMarker?.data?.actionId;
    const photosMarkers = [];

    const response = await RepositoryActions.getActionResourcesObjectForMap(actionId);

    if (isNotEmptyArray(response.data)) {
      response.data.forEach((resource) => {
        const newMarker = createPhotoMarker(actionMarker, resource, featureGroupKey);
        if (newMarker) photosMarkers.push(newMarker);
      });
    }

    return photosMarkers;
  }

  function createPhotoMarker(actionMarker, resource, featureGroupKey) {
    const markerData = getPhotoMarkerData(resource);

    if (!markerData) return null;

    const lineColor =
      resource?.geotimestamp_metadata?.lat != null
        ? ACTION_PHOTO_METADATA_CONNECTION_LINE_COLOR
        : ACTION_PHOTO_BASIC_CONNECTION_LINE_COLOR;

    const newMarker = clusterMap.value.addConnectedMarker(
      featureGroupKey,
      markerData,
      actionMarker,
      false,
      lineColor,
      50
    );

    return newMarker;
  }

  function getPhotoMarkerData(resource) {
    let lat = null;
    let lon = null;
    let icon = null;

    if (resource?.geotimestamp_metadata?.lat != null) {
      lat = resource.geotimestamp_metadata.lat;
      lon = resource.geotimestamp_metadata.lon;
      icon = 'circle_red';
    } else if (resource?.geotimestamp?.lat != null) {
      lat = resource.geotimestamp.lat;
      lon = resource.geotimestamp.lon;
      icon = 'circle_orange';
    }

    if (lat == null || lon == null) {
      console.warn(
        "getPhotoMarkerData(): Both geotimestamp_metadata and geotimestamp are not available in action's photo resource."
      );
      return null;
    }

    const markerId = ACTION_PHOTO_MARKER_ID_PREFIX + resource.id;
    const size = [27, 25];
    const anchor = [13.5, 12.5];

    return {
      markerId,
      lat,
      lng: lon,
      draggable: false,
      selected: false,
      selectable: false,
      popupCaption: null,
      zIndexOffset: 1000,
      divIcon: {
        className: '',
        iconSize: size,
        iconAnchor: anchor,
        html: `<img src='/static/img/markerIcons/${icon}.png' style='position: absolute; top: 0; left: 0; width: ${size[0]}px; height: ${size[1]}px;'/>`
      },
      markerType: ACTION_PHOTO_MARKER_TYPE_ID,
      resourcePath: resource.path
    };
  }

  function tryToHideAdditionalMarkersOfActionMarker(actionMarker) {
    if (actionMarker?.data?.markerId == null) {
      console.warn(
        "tryToHideAdditionalMarkersOfActionMarker(): You're trying to reference null markerId."
      );
      return;
    }

    const actionMarkerId = actionMarker.data.markerId;
    const featureGroupKey = getFeatureGroupKey(actionMarkerId);
    clusterMap.value.removeFeatureGroup(featureGroupKey);
  }

  function hideAdditionalMarkers(actionMarkerId) {
    const featureGroupKey = getFeatureGroupKey(actionMarkerId);
    clusterMap.value.removeFeatureGroup(featureGroupKey);
  }

  function hideAll() {
    if (!isNotEmptyArray(additionalMarkersActionMarkersIds.value)) return;

    additionalMarkersActionMarkersIds.value.forEach((actionMarkerId) =>
      hideAdditionalMarkers(actionMarkerId)
    );
    additionalMarkersActionMarkersIds.value = [];
  }

  function ensureActionMarkersAdditionalMarkersVisible(actionMarkerId) {
    const featureGroupKey = getFeatureGroupKey(actionMarkerId);
    const featureGroup = clusterMap.value.getFeatureGroupWithKey(featureGroupKey);
    clusterMap.value.ensureFeatureGroupVisible(featureGroup, 0.25, true);
  }

  return {
    tryToShowAdditionalMarkers,
    tryToHideAdditionalMarkersOfActionMarker,
    hideAll,
    ensureActionMarkersAdditionalMarkersVisible
  };
}
