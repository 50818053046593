<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { ORDER_CONTRACTORS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants.js';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper.js';
import { computeProgress, isNonEmptyString, isNotEmptyArray } from '../../helpers/utilsHelper.js';
import { useTableColumns } from '../../composables/tableColumns.js';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';

const IS_WAITING_DISASSEMBLY_STATUS_ID = 1;
const IN_PROGRESS_DISASSEMBLY_STATUS_ID = 2;
const RepositoryOrderContractors = RepositoryFactory.get('orderContractors');
const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  filter: { type: Object, default: null },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  noItemsText: { type: String, default: 'Brak elementów do wyświetlenia' },
  useCaseId: { type: String, default: null }
});
const emit = defineEmits(['refresh']);
const isLoading = ref(false);
const contractorsObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();

const initialColumns = [
  { key: 'disassembly_status', header: 'Status', tdStyle: 'white-space: nowrap;' },
  {
    key: 'user',
    header: 'Pracownik',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'realization',
    header: 'Realizacja',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'target',
    header: 'Cel',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  { key: 'destroyed', header: 'Wykonano', thStyle: 'white-space: nowrap;' },
  { key: 'destroyed_manualy', header: 'Ręcznie', thStyle: 'white-space: nowrap;' },
  { key: 'ln_present', header: 'LN', thStyle: 'white-space: nowrap;' },
  {
    key: 'gather_points',
    header: 'Składowanie',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'sync',
    header: 'Synchronizacja',
    thStyle: 'white-space: nowrap;',
    requiredKeysOverride: []
  }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns(
  'order_contractors_table' + (isNonEmptyString(props.useCaseId) ? `_${props.useCaseId}` : ''),
  initialColumns
);

// created
fetchData();

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryOrderContractors.getOrderContractorsObject(
    page,
    paginationStore.getPaginationItemsCount(ORDER_CONTRACTORS_TABLE_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter
  )
    .then((data) => {
      contractorsObject.value = data;
      updateTableData(contractorsObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function search() {
  fetchData();
}

function onItemsPerPageChanged() {
  fetchData();
}

function canStopTheOrder(contractor) {
  return (
    contractor.disassembly_status?.id === IS_WAITING_DISASSEMBLY_STATUS_ID ||
    contractor.disassembly_status?.id === IN_PROGRESS_DISASSEMBLY_STATUS_ID
  );
}

function canCancelTheOrder(contractor) {
  return contractor.disassembly_status?.id === IS_WAITING_DISASSEMBLY_STATUS_ID;
}

function onStopTheOrderButtonClicked(userOrderId) {
  Swal.fire({
    title: 'Demontaż kabli',
    html: 'Czy w zleceniu został wykonany demontaż kabli?',
    icon: 'question',
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'Tak',
    denyButtonText: 'Nie',
    cancelButtonText: 'Anuluj',
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    denyButtonColor: '#f8ac59',
    customClass: {
      title: 'swal2-title'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      stopOrder(userOrderId, 1);
    } else if (result.isDenied) {
      stopOrder(userOrderId, 0);
    }
  });
}

function stopOrder(userOrderId, ln_present) {
  Swal.fire(
    getSwalConfiguration(
      'Zakończenie zlecenia',
      `Czy na pewno chcesz zakończyć zlecenie dla danego wykonawcy  ${
        ln_present ? 'z zaznaczoną opcją' : 'bez zaznaczonej opcji'
      } wykonania demontażu kabli?`
    )
  ).then((result) => {
    if (result.value) {
      sendStopOrder(userOrderId, ln_present);
    }
  });
}

async function sendStopOrder(userOrderId, ln_present) {
  var response = await RepositoryOrders.stopOrCancelOrder(userOrderId, 'stop', ln_present);

  handleResponseWithMessages(response, `Zlecenie zostało zakończone dla danego wykonawcy.`, () => {
    emit('refresh');
    fetchData();
  });
}

function onCancelTheOrderButtonClicked(userOrderId) {
  Swal.fire(
    getSwalConfiguration(
      'Cofnięcie zlecenia',
      'Czy na pewno chcesz cofnąć zlecenie dla danego wykonawcy?'
    )
  ).then((result) => {
    if (result.value) cancelOrder(userOrderId);
  });
}

async function cancelOrder(userOrderId) {
  var response = await RepositoryOrders.stopOrCancelOrder(userOrderId, 'cancel');

  handleResponseWithMessages(response, 'Zlecenie zostało cofnięte dla danego wykonawcy.', () => {
    emit('refresh');
    fetchData();
  });
}

function onToggleLNPresentButtonClicked(userOrderId) {
  Swal.fire(
    getSwalConfiguration(
      'Zmiana demontażu kabli',
      'Czy na pewno chcesz zmienić status demontażu kabli dla danego wykonawcy?'
    )
  ).then((result) => {
    if (result.value) toggleLNPresent(userOrderId);
  });
}

async function toggleLNPresent(userOrderId) {
  var response = await RepositoryOrderContractors.changeUserOrderLNPresent(userOrderId);

  handleResponseWithMessages(response, 'Wykonanie demontażu kabli zostało zaktualizowane.', () => {
    emit('refresh');
    fetchData();
  });
}

function getSyncInfoColumnText(contractor) {
  const info = contractor?.calculated_statistics;

  if (info == null) return '-';

  const actionsSyncInfo =
    info?.uploaded_actions_count != null && info?.actions_count != null
      ? `Akcje: ${info.uploaded_actions_count}/${info.actions_count} <b>[${
          computeProgress(info.uploaded_actions_count, info.actions_count, 0) ?? '-'
        }]</b><br/>`
      : '';

  const photosSyncInfo =
    info?.uploaded_photos_count != null && info?.photos_count != null
      ? `Zdjęcia: ${info.uploaded_photos_count}/${info.photos_count} <b>[${
          computeProgress(info.uploaded_photos_count, info.photos_count, 0) ?? '-'
        }]</b><br/>`
      : '';

  const activitySyncInfo =
    info?.last_activity != null && info?.last_activity_diff != null
      ? `Ostatnia aktywność: ${getDateWithoutTime(info?.last_activity)} <b> [${
          info?.last_activity_diff
        }]</b>`
      : '';

  return actionsSyncInfo + photosSyncInfo + activitySyncInfo;
}

function getSyncInfoIconSource(contractor) {
  let statusIcon = contractor?.calculated_statistics?.status_icon;

  if (statusIcon == null) return null;

  switch (statusIcon) {
    case 1:
      return '/static/img/tick_icon.png';
    case 2:
      return '/static/img/arrow_down_icon.png';
    case 3:
      return '/static/img/excl_mark_icon.png';
  }

  return null;
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div class="row">
        <div :class="props.longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo :metaObject="contractorsObject?.meta ?? null" />

      <div
        v-if="contractorsObject?.data && contractorsObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="contractor in contractorsObject.data" :key="contractor.id">
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Status column -->
                  <template v-if="column.key === 'disassembly_status'">
                    <span
                      class="label label-primary"
                      :style="
                        contractor?.disassembly_status?.color != null
                          ? 'background-color:' + contractor.disassembly_status.color
                          : ''
                      "
                    >
                      {{ contractor?.disassembly_status?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- User column -->
                  <template v-else-if="column.key === 'user'">
                    {{
                      (contractor?.user?.firstName ?? '') + ' ' + (contractor?.user?.lastName ?? '')
                    }}
                  </template>

                  <!-- LN column -->
                  <template v-else-if="column.key === 'ln_present'">
                    <div style="display: flex; align-items: baseline; gap: 5px">
                      <i
                        v-if="contractor?.ln_present"
                        class="fa fa-check text-navy"
                        style="width: 15px"
                      />
                      <span v-else style="width: 15px">-</span>

                      <button
                        type="button"
                        @click="onToggleLNPresentButtonClicked(contractor.id)"
                        class="btn btn-xs btn-outline btn-link"
                        style="padding: 0px"
                      >
                        Zmień
                      </button>
                    </div>
                  </template>

                  <!-- Gather points column -->
                  <template v-else-if="column.key === 'gather_points'">
                    <span v-if="contractor?.gather_points > 0">
                      {{ contractor.gather_points }}
                    </span>
                    <span v-else>-</span>
                  </template>

                  <!-- Sync column -->
                  <template v-else-if="column.key === 'sync'">
                    <div style="display: flex; align-items: center; gap: 5px">
                      <div style="width: 15px">
                        <img
                          v-if="getSyncInfoIconSource(contractor) != null"
                          :src="getSyncInfoIconSource(contractor)"
                          style="height: 12px"
                        />
                      </div>
                      <span v-html="getSyncInfoColumnText(contractor)" />
                    </div>
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(contractor, column.key) ?? '-'
                        : contractor[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td></td>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    v-if="canCancelTheOrder(contractor)"
                    class="btn btn-outline btn-warning btn-xs mr-2"
                    type="button"
                    @click="onCancelTheOrderButtonClicked(contractor.id)"
                    onclick="event.stopPropagation()"
                  >
                    Cofnij zlecenie
                  </button>
                  <button
                    v-if="canStopTheOrder(contractor)"
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="onStopTheOrderButtonClicked(contractor.id)"
                    onclick="event.stopPropagation()"
                  >
                    Zakończ zlecenie
                  </button>
                  <slot name="buttons" :contractor="contractor"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="contractorsObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ props.noItemsText }}</p>
      </div>

      <div v-if="isNotEmptyArray(contractorsObject?.data)" class="row mt-3">
        <div class="col-xl-3">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="contractorsObject?.data"
            align="center"
            :data="contractorsObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="ORDER_CONTRACTORS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
