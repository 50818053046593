<script setup>
/* eslint-env jquery */
import { ref, computed, onMounted } from 'vue';
import { RepositoryFactory } from '../data/repositoryFactory';
import MultipleStatsBoxPanel from '../components/dashboard/MultipleStatsBoxPanel.vue';
import { isNotEmptyArray } from '../helpers/utilsHelper';
import ActionsLineChart from '../components/dashboard/ActionsLineChart.vue';
import FailureTypesPieChart from '../components/dashboard/FailureTypesPieChart.vue';
import WorkersEfficiencyBarChart from '../components/dashboard/WorkersEfficiencyBarChart.vue';
import OrdersChart from '../components/dashboard/OrdersChart.vue';
import DisassembliesAndFailuresPieChart from '../components/dashboard/DisassembliesAndFailuresPieChart.vue';
import FailuresDashboardRegister from '../components/dashboard/FailuresDashboardRegister.vue';
import DateRangeInput from '../components/utils/DateRangeInput.vue';
import { createToaster } from '@meforma/vue-toaster';

const RepositoryDashboard = RepositoryFactory.get('dashboard');

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const staticData = ref(null);
const graphData = ref(null);
const dateRangeData = ref(null);
const statsBoxesDateTime = ref(null);
const pickedYear = ref(null);
const pickedDateRange = ref({
  startDate: null,
  endDate: null
});
const appliedFilter = ref({
  year: null,
  dateRange: {
    startDate: null,
    endDate: null
  }
});

const isLoadingStaticData = ref(false);
const isLoadingGraphData = ref(false);
const isLoadingDateRangeData = ref(false);

const yearApplied = computed(() => {
  return filterApplied(pickedYear.value, appliedFilter.value.year);
});

const dateRangeApplied = computed(() => {
  return (
    filterApplied(pickedDateRange.value?.startDate, appliedFilter.value.dateRange.startDate) &&
    filterApplied(pickedDateRange.value?.endDate, appliedFilter.value.dateRange.endDate)
  );
});

const ordersStatusesData = computed(() => {
  if (!isNotEmptyArray(staticData.value?.data?.orders_statuses?.data)) return null;

  return staticData.value.data.orders_statuses.data.map((item) => ({
    id: item.id_order_status,
    tagCaption: item.name,
    tagColor: item.color,
    value: item.total
  }));
});

const userOrdersData = computed(() => {
  if (
    staticData.value?.data?.user_order_waiting == null ||
    staticData.value?.data?.user_order_in_progress == null
  )
    return null;

  return [
    {
      id: 1,
      tagCaption: staticData.value.data.user_order_waiting.title,
      tagColor: '#1ab394', //TODO to be modified
      value: staticData.value.data.user_order_waiting.count
    },
    {
      id: 1,
      tagCaption: staticData.value.data.user_order_in_progress.title,
      tagColor: '#1ab394', //TODO to be modified
      value: staticData.value.data.user_order_in_progress.count
    }
  ];
});

function loadStaticData() {
  isLoadingStaticData.value = true;

  RepositoryDashboard.getStaticData()
    .then((data) => (staticData.value = data))
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingStaticData.value = false;
      statsBoxesDateTime.value = new Date().toLocaleString();
    });
}

async function loadGraphData() {
  if (pickedYear.value == null) {
    console.log('loadGraphData(): PickedYear is not selected');
    toaster.show('Wybierz rok', { type: 'warning' });
    return;
  }

  isLoadingGraphData.value = true;

  RepositoryDashboard.getGraphData(pickedYear.value)
    .then((data) => {
      graphData.value = data?.data;
      appliedFilter.value.year = pickedYear.value;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingGraphData.value = false;
    });
}

async function loadDateRangeData() {
  if (pickedDateRange.value.startDate == null || pickedDateRange.value.endDate == null) {
    toaster.show('Obie daty z zakresu muszą być wybrane', { type: 'warning' });
    return;
  }

  isLoadingDateRangeData.value = true;

  RepositoryDashboard.getDateRangeData(
    pickedDateRange.value.startDate,
    pickedDateRange.value.endDate
  )
    .then((data) => {
      dateRangeData.value = data?.data;
      appliedFilter.value.dateRange.startDate = pickedDateRange.value.startDate;
      appliedFilter.value.dateRange.endDate = pickedDateRange.value.endDate;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingDateRangeData.value = false;
    });
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function updateYearFromPicker() {
  pickedYear.value = document.getElementById('yearFormGroupInputId').value;
}

function setInitialDates() {
  let now = new Date();
  let month = now.getMonth() + 1;
  let monthString = month.toString().length === 1 ? '0' + month : month;

  pickedYear.value = now.getFullYear();
  pickedDateRange.value.startDate = `${now.getFullYear()}-01-01`;
  pickedDateRange.value.endDate = now.getFullYear() + '-' + monthString + '-' + now.getDate();
}

// created
setInitialDates();

onMounted(() => {
  const datepickerConfig = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    autoclose: true,
    format: 'yyyy',
    minViewMode: 'years'
  };

  $('#yearFormGroupId .input-group.date').datepicker(datepickerConfig);
  $(document.body).on('change', 'input[id^="yearFormGroupInputId"]', updateYearFromPicker);

  loadStaticData();
  loadGraphData();
  loadDateRangeData();
});
</script>

<template>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="static-data-container">
          <MultipleStatsBoxPanel
            style="flex-grow: 2.5"
            class="static-data-element"
            :header="`Bieżące zlecenia na dzień ${statsBoxesDateTime ?? '-'}`"
            :statsObjects="ordersStatusesData"
            :isLoading="isLoadingStaticData"
          />
          <MultipleStatsBoxPanel
            class="static-data-element"
            :header="`Bieżące realizacje na dzień ${statsBoxesDateTime ?? `-`}`"
            :statsObjects="userOrdersData"
            :isLoading="isLoadingStaticData"
          />
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="ibox">
              <div class="ibox-content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="date-picker-container">
                      <div class="form-group" id="yearFormGroupId" style="flex-grow: 1">
                        <label class="font-normal" :class="yearApplied ? '' : 'text-warning'">
                          Rok
                        </label>

                        <div class="input-group date">
                          <span class="input-group-addon" :style="'background-color: #e9ecef'">
                            <i class="fa fa-calendar"></i>
                          </span>

                          <input
                            id="yearFormGroupInputId"
                            type="text"
                            class="form-control"
                            v-model="pickedYear"
                          />
                        </div>
                      </div>

                      <button
                        class="btn btn-outline"
                        :class="[yearApplied ? 'btn-default' : 'btn-info']"
                        style="margin-top: 10px"
                        @click="loadGraphData"
                        :disabled="yearApplied"
                      >
                        Zastosuj
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="date-picker-container">
                      <DateRangeInput
                        style="flex-grow: 1"
                        inputId="dashboardDateRangeInputId"
                        label="Zakres dat"
                        :labelCssClass="dateRangeApplied ? '' : 'text-warning'"
                        :startDate="pickedDateRange.startDate"
                        :endDate="pickedDateRange.endDate"
                        @startDateChanged="
                          (p1) => {
                            pickedDateRange.startDate = p1;
                            if (pickedDateRange.endDate == null) pickedDateRange.endDate = p1;
                          }
                        "
                        @endDateChanged="
                          (p1) => {
                            pickedDateRange.endDate = p1;
                          }
                        "
                      />

                      <button
                        class="btn btn-outline"
                        :class="[dateRangeApplied ? 'btn-default' : 'btn-info']"
                        style="margin-top: 10px"
                        @click="loadDateRangeData"
                        :disabled="dateRangeApplied"
                      >
                        Zastosuj
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <ActionsLineChart
              header="Akcje"
              :stats="null"
              :graphData="graphData"
              :right-header-text="`Dla roku: ${appliedFilter.year ?? '-'}`"
              :isLoading="isLoadingGraphData"
            />
          </div>

          <div class="col-lg-6">
            <OrdersChart
              header="Zlecenia"
              :stats="null"
              :graphData="graphData"
              :right-header-text="`Dla roku: ${appliedFilter.year ?? '-'}`"
              :isLoading="isLoadingGraphData"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-12">
                <WorkersEfficiencyBarChart
                  :data="dateRangeData"
                  :right-header-text="`Dla zakresu: ${appliedFilter.dateRange.startDate ?? ''} - ${
                    appliedFilter.dateRange.endDate ?? ''
                  }`"
                  :isLoading="isLoadingDateRangeData"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <DisassembliesAndFailuresPieChart
                  :data="dateRangeData"
                  :right-header-text="`Dla zakresu: ${appliedFilter.dateRange.startDate ?? ''} - ${
                    appliedFilter.dateRange.endDate ?? ''
                  }`"
                  :isLoading="isLoadingDateRangeData"
                />
              </div>

              <div class="col-lg-6">
                <FailureTypesPieChart
                  :data="dateRangeData"
                  :right-header-text="`Dla zakresu: ${appliedFilter.dateRange.startDate ?? ''} - ${
                    appliedFilter.dateRange.endDate ?? ''
                  }`"
                  :isLoading="isLoadingDateRangeData"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <FailuresDashboardRegister
              :start-date="appliedFilter.dateRange.startDate"
              :end-date="appliedFilter.dateRange.endDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.static-data-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.static-data-element {
  flex-grow: 1;
  flex-shrink: 1;
}

.date-picker-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
