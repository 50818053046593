<script setup>
import { ref, unref } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  usersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import UsersTableFilterAndSearch from '@/components/users/UsersTableFilterAndSearch.vue';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { USERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { useAuthStore } from '@/stores/auth';
import { ACCESS_KEY_USER_CREATE } from '../../data/constants/authConstants.js';
import { useTableColumns } from '../../composables/tableColumns.js';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';
import { isNonEmptyString, isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryUsers = RepositoryFactory.get('users');

const props = defineProps({
  filter: { type: Object, default: null },
  selectableItems: { type: Boolean, default: false },
  showHeader: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  addButton: { type: Boolean, default: false },
  editButton: { type: Boolean, default: false },
  noItemsText: { type: String, default: 'Brak elementów do wyświetlenia' },
  showDefaultButtons: { type: Boolean, default: true },
  clickableRows: { type: Boolean, default: true },
  showFilters: { type: Boolean, default: false },
  multiSelections: { type: Boolean, default: false },
  initiallyPickedItems: { type: Array, default: null },
  useCaseId: { type: String, default: null }
});

const initialColumns = [
  { key: 'last_activity', header: 'Obecny' },
  { key: 'firstName', header: 'Imię', sortable: true, sortKey: sortingOptions.FIRST_NAME },
  { key: 'lastName', header: 'Nazwisko', sortable: true, sortKey: sortingOptions.LAST_NAME },
  { key: 'phone', header: 'Telefon' },
  { key: 'email', header: 'Email', sortable: true, sortKey: sortingOptions.EMAIL },
  { key: 'type.name', header: 'Typ' },
  { key: 'active', header: 'Aktywny' },
  { key: 'additional_verify', header: '2FA' },
  { key: 'color', header: 'Kolor' }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns(
  'users_table' + (isNonEmptyString(props.useCaseId) ? `_${props.useCaseId}` : ''),
  initialColumns
);

const emit = defineEmits(['on-completed']);

const router = useRouter();
const isLoading = ref(false);
const usersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const userFilter = ref(null);
const paginationStore = usePaginationStore();
const usersTableFilterAndSearchChildComponent = ref(null);
const pickedItems = ref([]);
const authStore = useAuthStore();

function fetchData(page = 1) {
  getUsers(page);
}

function getUsers(page = 1) {
  isLoading.value = true;

  RepositoryUsers.getUsersObject(
    page,
    paginationStore.getPaginationItemsCount(USERS_TABLE_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter,
    userFilter.value
  )
    .then((data) => {
      if (props.multiSelections) {
        data.data.forEach((element) => {
          element.picked = pickedItems.value.find((item) => item.id == element.id) != null;
        });
      }

      usersObject.value = data;
      usersTableFilterAndSearchChildComponent.value?.updateAppliedFilters();
      updateTableData(usersObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function addNewUser() {
  router.push({ name: 'userAddEdit' });
}

function showDetails(user) {
  router.push({ name: 'userDetails', params: { id: user.id } });
}

function edit(user) {
  router.push({ name: 'userAddEdit', params: { id: user.id } });
}

function search() {
  fetchData();
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function pickItem(user) {
  if (!props.multiSelections) {
    emit('on-completed', user);
    return;
  }

  user.picked = !user.picked;

  const userFoundInPicked = pickedItems.value.find((item) => item.id == user.id);

  if (user.picked && userFoundInPicked == null) {
    pickedItems.value.push(user);
  } else if (!user.picked && userFoundInPicked != null) {
    const index = pickedItems.value.indexOf(userFoundInPicked);
    pickedItems.value.splice(index, 1);
  }

  emit('on-completed', user, unref(pickedItems));
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  fetchData();
}

function onItemsPerPageChanged() {
  fetchData();
}

// created
pickedItems.value = [];

if (Array.isArray(props.initiallyPickedItems)) {
  props.initiallyPickedItems.forEach((element) => {
    pickedItems.value.push(element);
  });
}

function showLastConfiguration(user) {
  if (user.last_configuration != null)
    alert(JSON.stringify(JSON.parse(user.last_configuration), null, 2));
}

fetchData();
</script>

<template>
  <div class="ibox">
    <div v-if="props.showHeader" class="ibox-title">
      <h5>Użytkownicy</h5>
      <div v-if="props.addButton" class="ibox-tools">
        <a
          v-if="authStore.canAccess(ACCESS_KEY_USER_CREATE)"
          @click="addNewUser"
          class="btn btn-primary btn-xs"
          >+ Nowy użytkownik</a
        >
      </div>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <users-table-filter-and-search
        v-if="showFilters"
        ref="usersTableFilterAndSearchChildComponent"
        :longerSearchBar="props.longerSearchBar"
        @fetchData="onFetchDataWithFiltersRequest"
      />

      <div v-else class="row">
        <div :class="props.longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo :metaObject="usersObject ? usersObject.meta : null" />

      <div v-if="isNotEmptyArray(usersObject?.data)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="user in usersObject.data"
              :key="user.id"
              @click="
                clickableRows ? (props.selectableItems ? pickItem(user) : showDetails(user)) : ''
              "
              :style="clickableRows ? 'cursor: pointer;' : ''"
            >
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Last activity column -->
                  <template v-if="column.key === 'last_activity'">
                    <b>{{ user?.last_activity_difference }}</b>
                    <div v-if="user.last_activity">
                      ({{ user?.last_activity }})
                      <button
                        class="btn btn-outline btn-primary btn-xs fa fa-info"
                        type="button"
                        @click.stop="showLastConfiguration(user)"
                      ></button>
                    </div>
                  </template>

                  <!-- Active status column -->
                  <template v-else-if="column.key === 'active'">
                    <i
                      :class="user.active ? 'fa fa-check text-navy' : 'fa fa-times text-danger'"
                    ></i>
                  </template>

                  <!-- 2FA column -->
                  <template v-else-if="column.key === 'additional_verify'">
                    <i
                      :class="
                        user.additional_verify === 1
                          ? 'fa fa-check text-navy'
                          : 'fa fa-times text-danger'
                      "
                    ></i>
                  </template>

                  <!-- Color column -->
                  <template v-else-if="column.key === 'color'">
                    <div
                      v-if="user.color"
                      :style="`width: 40px; height: 20px; border-radius: 5px; background-color: ${user.color}`"
                    ></div>
                    <template v-else>-</template>
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(user, column.key) ?? '-'
                        : user[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td>
                <div class="float-right">
                  <span v-if="props.showDefaultButtons">
                    <button
                      v-if="!props.selectableItems"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="showDetails(user)"
                      onclick="event.stopPropagation()"
                    >
                      Pokaż
                    </button>

                    <button
                      v-if="props.selectableItems && !props.multiSelections"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="pickItem(user)"
                      onclick="event.stopPropagation()"
                    >
                      Wybierz
                    </button>

                    <button
                      v-if="props.selectableItems && props.multiSelections"
                      class="btn btn-primary btn-xs mr-2"
                      :class="user.picked ? 'btn-danger' : ''"
                      type="button"
                      @click="pickItem(user)"
                      onclick="event.stopPropagation()"
                    >
                      {{ user.picked ? 'Odznacz' : 'Zaznacz' }}
                    </button>

                    <button
                      v-if="props.editButton"
                      class="btn btn-outline btn-primary btn-xs"
                      type="button"
                      @click="edit(user)"
                      onclick="event.stopPropagation()"
                    >
                      <span>Edytuj</span>
                    </button>
                  </span>
                  <slot name="buttons" :user="user"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="usersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ noItemsText }}</p>
      </div>

      <div v-if="isNotEmptyArray(usersObject?.data)" class="row mt-3">
        <div class="col-xl-3">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="usersObject?.data"
            align="center"
            :data="usersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="USERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
