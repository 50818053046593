<script setup>
import { computed } from 'vue';
import ChartPanel from './ChartPanel.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { monthsXLabels } from '../../helpers/dashboardHelper';

const props = defineProps({
  header: { type: String, default: null },
  stats: { type: Array, default: () => [] },
  graphData: { type: Array, default: () => [] },
  rightHeaderText: { type: String, default: null },
  isLoading: { type: Boolean, default: false }
});

var graphOptions = {
  xaxis: {
    ticks: monthsXLabels,
    autoscaleMargin: 0.05
  },
  yaxes: [{ position: 'left' }],
  legend: {
    noColumns: 1,
    labelBoxBorderColor: '#000000',
    position: 'nw'
  },
  grid: {
    color: '#bababa',
    hoverable: true,
    clickable: true,
    borderWidth: 0
  },
  tooltip: true,
  tooltipOpts: {
    content: (label, x, y) => {
      switch (label) {
        case 'Niepowodzenia':
          return `Niepowodzenia: ${y}`;
        case 'Demontaże':
          return `Demontaże: ${y}`;
        case 'Demontaże dodatkowe':
          return `Demontaże dodatkowe: ${y}`;
        default:
          return `${label}: ${y}`;
      }
    }
  }
};

const dataAvailable = computed(() => isNotEmptyArray(props.graphData));

const graphDatasets = computed(() => {
  if (!dataAvailable.value) return null;

  const result = [];
  if (failuresDataset.value) result.push(failuresDataset.value);
  if (demPointsDoneDataset.value) result.push(demPointsDoneDataset.value);
  if (extraDemPointsDoneDataset.value) result.push(extraDemPointsDoneDataset.value);

  return result;
});

const failuresData = computed(() => {
  if (!dataAvailable.value) return null;

  return props.graphData.map((item) => [item.month, item.failures]);
});

const failuresDataset = computed(() => {
  if (!dataAvailable.value) return null;

  return {
    label: 'Niepowodzenia',
    data: failuresData.value,
    color: '#c61c1c',
    lines: {
      lineWidth: 1,
      fill: true,
      fillColor: { colors: [{ opacity: 0.2 }, { opacity: 0.4 }] }
    }
  };
});

const demPointsDoneData = computed(() => {
  if (!dataAvailable.value) return null;
  return props.graphData.map((item) => [item.month, item.dem_points_done]);
});

const demPointsDoneDataset = computed(() => {
  if (!dataAvailable.value) return null;

  return {
    label: 'Demontaże',
    data: demPointsDoneData.value,
    color: '#1ab394',
    lines: {
      lineWidth: 1,
      fill: true,
      fillColor: { colors: [{ opacity: 0.2 }, { opacity: 0.4 }] }
    }
  };
});

const extraDemPointsDoneData = computed(() => {
  if (!dataAvailable.value) return null;
  return props.graphData.map((item) => [item.month, item.dem_points_done_extra]);
});

const extraDemPointsDoneDataset = computed(() => {
  if (!dataAvailable.value) return null;

  return {
    label: 'Demontaże dodatkowe',
    data: extraDemPointsDoneData.value,
    color: '#1C84C6',
    lines: {
      lineWidth: 1,
      fill: true,
      fillColor: { colors: [{ opacity: 0.2 }, { opacity: 0.4 }] }
    }
    // splines: {
    //   show: true,
    //   tension: 0.3,
    //   lineWidth: 1,
    //   fill: 0.1
    // }
  };
});
</script>

<template>
  <ChartPanel
    :header="props.header"
    :stats="stats"
    :graphDatasets="graphDatasets"
    :graphOptions="graphOptions"
    :rightHeaderText="props.rightHeaderText"
    :isLoading="props.isLoading"
  />
</template>

<style scoped></style>
